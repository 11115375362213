import { noop } from 'lodash';
import { Button } from 'semantic-ui-react';

import { useSetDefaultPaymentMethodMutation } from 'src/api/billing';
import { BillingPaymentMethod } from 'src/types';

type Props = {
  paymentMethod: BillingPaymentMethod;
};

const SetDefaultPaymentMethod = ({ paymentMethod: { id, isDefault } }: Props) => {
  // const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useSetDefaultPaymentMethodMutation();

  const onClick = async () => {
    try {
      await mutateAsync({ id });
    } catch (e: any) {
      // apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Button
      type="button"
      basic={!isDefault}
      color={isDefault ? 'blue' : undefined}
      floated="right"
      loading={isLoading}
      onClick={isDefault ? noop : onClick}
      size="tiny"
      style={{ marginLeft: '1rem', pointerEvents: isDefault ? 'none' : '' }}
    >
      {isDefault ? 'Default' : 'Set Default'}
    </Button>
  );
};

export default SetDefaultPaymentMethod;
