import { FC } from 'react';

import { Main, PageLayout } from 'src/styles';
import Header from '../Header';

const BasicLayout: FC = ({ children }) => {
  return (
    <PageLayout>
      <Header />
      <Main>{children}</Main>
    </PageLayout>
  );
};

export default BasicLayout;
