import { Helmet } from 'react-helmet-async';
import { Message } from 'semantic-ui-react';

import Header from 'src/components/Header';
import { Center, Main, PageContainer, PageLayout } from 'src/styles';

const NotFound = () => (
  <PageLayout>
    <Header />
    <Main>
      <PageContainer flex>
        <Helmet>
          <title>404 - Not Found | leadguard</title>
        </Helmet>

        <Center>
          <Message color="red">
            <Message.Header>🚨 Error!</Message.Header>
            <p>The page you are looking for could not be found.</p>
          </Message>
        </Center>
      </PageContainer>
    </Main>
  </PageLayout>
);

export default NotFound;
