import { useMutation, useQuery, useQueryClient } from 'react-query';

import type {
  Account,
  AccountApiToken,
  BillingPaymentIntentAction,
  BillingSubscriptionPayment,
  PaginatedResponse,
} from '../types';
import { ApiResponse, http } from './http-common';

export type AccountResponse = ApiResponse & {
  account?: Account;
};

export type AccountsResponse = ApiResponse & {
  accounts: PaginatedResponse<Account>;
};

export type AccountBillingSubscriptionPaymentsResponse = ApiResponse & {
  payments: PaginatedResponse<BillingSubscriptionPayment>;
};

export type AccountApiTokensResponse = ApiResponse & {
  apiTokens: PaginatedResponse<AccountApiToken>;
};

export type AddAccountParams = {
  name: string;
};

export const useAddAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ name }: AddAccountParams) => {
      const res = await http.post<AccountResponse>('/api/accounts', { name });
      return res.data.account;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};

export type ListAccountApiTokensParams = {
  accountId: string;
  limit: number;
  offset: number;
};

export const useListAccountApiTokensQuery = ({ accountId, ...params }: ListAccountApiTokensParams) => {
  return useQuery(['accounts', accountId, 'api-tokens', params], async () => {
    if (!accountId) return undefined;
    const res = await http.get<AccountApiTokensResponse>(`/api/accounts/${accountId}/api-tokens`, { params });
    return res.data.apiTokens;
  });
};

export type ListAccountsParams = {
  limit: number;
  offset: number;
};

export const useListAccountsQuery = (params: ListAccountsParams) => {
  return useQuery(
    ['accounts', params],
    async () => {
      const res = await http.get<AccountsResponse>('/api/accounts', { params });
      return res.data.accounts;
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const useGetAccountQuery = (accountId: string) => {
  return useQuery(['accounts', accountId], async () => {
    const res = await http.get<AccountResponse>(`/api/accounts/${accountId}`);
    return res.data.account;
  });
};

export type UpdateAccountParams = {
  id: string;
  name?: string;
  billing_alert_emails?: string;
  billing_autopay_enabled?: boolean;
  billing_autopay_threshold?: number;
  billing_autopay_amount?: number;
};

export const useUpdateAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...params }: UpdateAccountParams) => {
      const res = await http.post<AccountResponse>(`/api/accounts/${id}`, params);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['accounts', account.id]);
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.invalidateQueries(['auth/account']);
        queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};

export type PaymentIntentResponse = ApiResponse & {
  clientSecret: string;
  paymentIntentId: string;
};

export type ListBillingSubscriptionPaymentsParams = {
  accountId: string;
  limit: number;
  offset: number;
};

export const useListBillingSubscriptionPaymentsQuery = ({
  accountId,
  ...params
}: ListBillingSubscriptionPaymentsParams) => {
  return useQuery(['accounts', accountId, 'subscription-payments', params], async () => {
    const res = await http.get<AccountBillingSubscriptionPaymentsResponse>(
      `/api/accounts/${accountId}/billing/subscription-payments`,
      { params }
    );
    return res.data.payments;
  });
};

export type BillingPaymentIntentParams = {
  accountId: string;
  action: BillingPaymentIntentAction;
  amount: number;
  paymentMethodId: string;
};

export const useAccountBillingPaymentIntentMutation = () => {
  return useMutation(async ({ accountId, ...params }: BillingPaymentIntentParams) => {
    const res = await http.post<PaymentIntentResponse>(`/api/accounts/${accountId}/billing/payment-intent`, params);
    return res.data;
  });
};

export type BillingCancelPaymentIntentParams = {
  accountId: string;
  paymentIntentId: string;
};

export const useAccountBillingCancelPaymentIntentMutation = () => {
  return useMutation(async ({ accountId, ...params }: BillingCancelPaymentIntentParams) => {
    const res = await http.delete<ApiResponse>(`/api/accounts/${accountId}/billing/payment-intent`, { params });
    return res.data;
  });
};
