import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { useGetUserProfileQuery, useSwitchAccountMutation } from 'src/api/auth';

type Props = {
  id: string;
};

const SwitchAccountButton = ({ id }: Props) => {
  const { push } = useHistory();
  const { mutateAsync } = useSwitchAccountMutation();
  const { data } = useGetUserProfileQuery();

  const onSwitchAccountClick = useCallback(
    (id: string) => async () => {
      await mutateAsync({ id });
      push('/');
    },
    [push, mutateAsync]
  );

  return (
    <>
      {data?.activeAccount && data.activeAccount.id === id ? (
        <Button color="green">Activated</Button>
      ) : (
        <Button icon="sync" onClick={onSwitchAccountClick(id)} title="Switch Account" />
      )}
    </>
  );
};

export default SwitchAccountButton;
