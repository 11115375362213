import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { ListCertificatesFilters, useGetListCertificatesFilterOptionsQuery } from 'src/api/certificates';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { PageContainer, PageHeader } from 'src/styles';
import { accountBillingIsCurrent } from 'src/utils';
import CertificatesListBody from './CertificatesListBody';

const getFiltersFromQuery = (query: URLSearchParams): ListCertificatesFilters => {
  const search = query.get('search') || undefined;
  const url = query.get('url') || undefined;

  return { search, url };
};

const getSearchFromQuery = (query: URLSearchParams): string => {
  return query.get('search') || '';
};

const CertificatesList = () => {
  const { push } = useHistory();
  const query = useSearchQuery();
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();
  const { data: filterOptions, isLoading: optionsLoading } = useGetListCertificatesFilterOptionsQuery();
  const [filters, setFilters] = useState<ListCertificatesFilters>(() => getFiltersFromQuery(query));
  const [searchQuery, setSearchQuery] = useState<string>(() => getSearchFromQuery(query));

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onSubmit = useCallback(() => {
    query.set('search', searchQuery);
    query.set('page', '1');
    push({ search: query.toString() });
    // NOTE: the useEffect hook above (watching query) will set the filter state for us
  }, [push, query, searchQuery]);

  if (profileLoading) return <Loading />;
  if (!profile?.user) return <Redirect to="/auth/login" />;
  if (!profile.activeAccount) return <Redirect to="/accounts" />;

  const isAdmin = profile.user.role === 'admin';

  if (!accountBillingIsCurrent(profile.activeAccount) && !isAdmin) {
    return <Redirect to="/account/billing" />;
  }

  return (
    <ConsoleLayout>
      <PageContainer>
        <Helmet>
          <title>Certificates | leadguard</title>
        </Helmet>

        <PageHeader>Certificates</PageHeader>

        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Input
              label="Search"
              placeholder="by ID or Phone Number"
              value={searchQuery || ''}
              onChange={(_, { value }) => setSearchQuery(value)}
              onBlur={onSubmit}
            />

            <Form.Select
              label="URL"
              placeholder="All URLs"
              clearable
              value={filters.url || ''}
              onChange={(_, { value }) => {
                query.set('url', String(value));
                query.set('page', '1');
                push({ search: query.toString() });

                // NOTE: the useEffect hook above (watching query) will set the filter state for us
              }}
              options={filterOptions?.urls.map(url => ({ key: url, text: url, value: url })) || []}
              loading={optionsLoading}
            />

            <Form.Button label="&nbsp;" icon>
              <Icon name="search" />
            </Form.Button>
          </Form.Group>
        </Form>

        <PaginatedTable
          headers={['Certificate ID', 'Form Data', 'URL', 'Duration', 'Event Count', '']}
          renderBody={(props: RenderProps) => <CertificatesListBody {...props} filters={filters} />}
        />
      </PageContainer>
    </ConsoleLayout>
  );
};

export default CertificatesList;
