import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Label, Table } from 'semantic-ui-react';

import { ListAccountsFilters, useListAccountsQuery } from 'src/api/admin/accounts';
import { RenderProps } from 'src/components/PaginatedTable';
import SwitchAccountButton from 'src/components/SwitchAccountButton';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { User } from 'src/types';
import ToggleAccountEnabledButton from './ToggleAccountEnabledButton';

type Props = RenderProps & {
  filters?: ListAccountsFilters;
};

const AdminAccountsListBody = ({ limit, currentPage, setPageCount, filters }: Props) => {
  const history = useHistory();
  const { data: accounts } = useListAccountsQuery({ limit, offset: limit * (currentPage - 1), ...filters });

  useEffect(() => {
    let count = 1;
    if (typeof accounts !== 'undefined') {
      count = Math.ceil(accounts.total / limit);
    }
    setPageCount(count);
  }, [accounts, limit, setPageCount]);

  return (
    <>
      {accounts?.data.map(account => {
        const owners: User[] = (account.userAccess || [])
          .filter(ua => ua.user && ua.roles.includes('owner'))
          .map(ua => ua.user) as User[];

        return (
          <Table.Row key={account.id}>
            <Table.Cell collapsing>
              <ToggleAccountEnabledButton account={account} />
            </Table.Cell>
            <Table.Cell>
              <Row style={{ flexDirection: 'column' }}>
                <Link to={`/admin/accounts/${account.id}`}>
                  <strong>{account.name}</strong>
                </Link>
                <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
                  {account.id}
                </span>
              </Row>
            </Table.Cell>
            <Table.Cell>
              {owners.map(o => (
                <Label key={o.id}>{o.email}</Label>
              ))}
            </Table.Cell>
            <Table.Cell>
              {account.billing.enabled ? (
                <Label color="blue">{account.billing.rate} per month</Label>
              ) : (
                <Label>Disabled</Label>
              )}
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <SwitchAccountButton id={account.id} />
              <Button
                icon="pencil"
                color="blue"
                onClick={() => history.push(`/admin/accounts/${account.id}`)}
                title="Edit"
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminAccountsListBody;
