import { useMutation, useQueryClient } from 'react-query';

import { BusinessProfile } from 'src/types';
import { AccountResponse } from './accounts';
import { ApiResponse, http } from './http-common';

export type UpdateBusinessProfileInput = {
  accountId: string;
  businessProfile: BusinessProfile;
};

export const useUpdateBusinessProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, ...input }: UpdateBusinessProfileInput) => {
      const res = await http.post<AccountResponse>(`/api/accounts/${accountId}/business-profile`, input);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};

export const useCompleteOnboardingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (accountId: string) => {
      const res = await http.post<ApiResponse>(`/api/accounts/${accountId}/complete-onboarding`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};
