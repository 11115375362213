import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Certificate, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type CertificatesResponse = ApiResponse & {
  certificates: PaginatedResponse<Certificate>;
};

export type CertificateResponse = ApiResponse & {
  certificate: Certificate;
  events: any[];
};

export type ListCertificatesFilters = {
  search?: string;
  url?: string;
};

export type ListCertificatesParams = ListCertificatesFilters & {
  limit: number;
  offset: number;
};

export const useListCertificatesQuery = (params: ListCertificatesParams) => {
  return useQuery(['certificates', params], async () => {
    const res = await http.get<CertificatesResponse>('/api/certificates', { params });
    return res.data.certificates;
  });
};

type ListCertificatesFilterOptionsResponse = ApiResponse & {
  urls: string[];
};

export const useGetListCertificatesFilterOptionsQuery = () => {
  return useQuery(
    ['certificates', 'filter-options'],
    async () => {
      const res = await http.get<ListCertificatesFilterOptionsResponse>(`/api/certificates/filter-options`);
      return res.data;
    },
    { retry: false }
  );
};

export const useGetCertificateQuery = (id: string, token: string | null) => {
  return useQuery(
    ['certificates', id, token],
    async () => {
      const res = await http.get<CertificateResponse>(`/api/certificates/${id}`, { params: { token } });
      return res.data;
    },
    { retry: false }
  );
};

export type ShareCertificateInput = {
  certificateId: string;
  emails: string[];
  message?: string;
};

export const useShareCertificateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ certificateId: id, ...input }: ShareCertificateInput) => {
      const res = await http.post<ApiResponse>(`/api/certificates/${id}/share`, input);
      return res.data;
    },
    {
      onSuccess: (_, { certificateId: id }) => {
        queryClient.invalidateQueries(['certificates', id]);
      },
    }
  );
};

export const useDeleteCertificateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<ApiResponse>(`/api/certificates/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['certificates']);
      },
    }
  );
};
