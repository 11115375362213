import { useEffect, useRef } from 'react';
import rrwebPlayer from 'rrweb-player';
import { Header } from 'semantic-ui-react';

import { PlayerContainer } from './styles';

type Props = {
  events: any[];
};

const TabPaneReplay = ({ events }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!events || !ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const CONTROLS_HEIGHT = 80;

    new rrwebPlayer({
      target: ref.current, // customizable root element
      props: {
        autoPlay: false,
        width: rect.width,
        height: rect.height - CONTROLS_HEIGHT,
        // @ts-ignore
        events,
      },
    });
  }, [events]);

  return (
    <>
      <Header>Playback of the user&apos;s visit to your website.</Header>
      <PlayerContainer id="rrweb-container" ref={ref} />
    </>
  );
};

export default TabPaneReplay;
