import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentIntent } from '@stripe/stripe-js';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Form, Grid, Icon, Loader, Message, Modal, Table } from 'semantic-ui-react';

import { useAccountBillingCancelPaymentIntentMutation, useAccountBillingPaymentIntentMutation } from 'src/api/accounts';
import { useGetAccountBillingDetailsQuery } from 'src/api/billing';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Account, BillingPaymentIntentAction } from 'src/types';
import PaymentButton from './PaymentButton';
import PaymentMethod from './PaymentMethod';

type Props = {
  account: Account;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AccountBillingSubscriptionPayment = ({ account: a }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData | undefined>();
  const [clientSecret, setClientSecret] = useState<string>('');
  const [paymentIntentId, setPaymentIntentId] = useState<string>('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');
  const { data: paymentMethods, isLoading: paymentMethodsLoading } = useGetAccountBillingDetailsQuery();
  const { mutateAsync: paymentIntent, isLoading: intentIsLoading } = useAccountBillingPaymentIntentMutation();
  const { mutateAsync: cancelIntent } = useAccountBillingCancelPaymentIntentMutation();
  const queryClient = useQueryClient();

  const amount = a.billing.rate ? String(a.billing.rate) : '500';

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setSelectedPaymentMethod('');
    setPaymentIntentId('');
    setClientSecret('');
    setOpen(false);

    if (paymentIntentId !== '') {
      cancelIntent({ accountId: a.id, paymentIntentId });
    }

    queryClient.invalidateQueries(['auth/account']);
    queryClient.invalidateQueries(['auth/profile']);
  }, [a.id, cancelIntent, paymentIntentId, queryClient]);

  // const validate = useCallback((input: string) => {
  //   const validationErrors: ValidationErrors = {};

  //   if (Number.isNaN(Number(input))) {
  //     validationErrors.amount = 'invalid number';
  //   } else if (Number(input) <= 0) {
  //     validationErrors.amount = 'please select an amount';
  //   }
  //   // else if (Number(input) % 500 !== 0) {
  //   //   validationErrors.amount = 'amount must be divisible by 500';
  //   // }

  //   setErrors(validationErrors);

  //   return validationErrors;
  // }, []);

  // const onChange = useCallback(
  //   (_, { value }) => {
  //     setAmount(value);
  //     setSelectedPaymentMethod('');
  //     setClientSecret('');
  //     setPaymentIntentId('');
  //     validate(value);

  //     if (paymentIntentId !== '') {
  //       cancelIntent({ paymentIntentId });
  //     }
  //   },
  //   [cancelIntent, paymentIntentId, validate]
  // );

  const selectPaymentMethod = useCallback(
    (id: string) => async () => {
      setApiMessage(undefined);
      setSelectedPaymentMethod(id);

      if (id === '' && paymentIntentId !== '') {
        cancelIntent({ accountId: a.id, paymentIntentId });
      }

      if (!id || Number.isNaN(Number(amount)) || Number(amount) < 500) return;

      try {
        const data = await paymentIntent({
          accountId: a.id,
          action: BillingPaymentIntentAction.subscribe,
          paymentMethodId: id,
          amount: Number(amount),
        });
        setClientSecret(data.clientSecret);
        setPaymentIntentId(data.paymentIntentId);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [a.id, amount, cancelIntent, paymentIntent, paymentIntentId]
  );

  const onSubmit = useCallback(async (pi?: PaymentIntent) => {
    setApiMessage(undefined);

    if (!pi) {
      setApiMessage({ success: false, status: 400, message: 'Invalid or missing payment intent.' });
      return;
    }

    // CC payments are processed immediately and should result in the "succeeded" status
    // ACH payments take a few days to process and should result in the "processing" status
    if (pi.status !== 'succeeded' && pi.status !== 'processing') {
      // console.log(pi);
      setApiMessage({ success: false, status: 400, message: `Payment failed. ${pi.description}` });
      return;
    }

    setApiMessage({ success: true, status: 200, message: 'Thank you! You payment was successful.' });
    setPaymentIntentId('');
    setClientSecret('');

    // NOTE: closing the modal automatically causes cancelIntent() to be called when it
    // shouldn't be. This in turn causes an error in the backend with message:
    // "You cannot cancel this PaymentIntent because it has a status of succeeded."
    //
    // This is likely because the version of the onClose() callback being called here
    // contains the paymentIntentId still.
    //
    // setTimeout(() => onClose(true), 3000);
  }, []);

  const subtotal = Number(amount) || 0;
  const fees = subtotal > 0 ? (subtotal + 0.32) / (1 - 0.029) - subtotal : 0;
  const total = subtotal + fees;

  const selectedPaymentMethodType = paymentMethods?.find(pm => pm.id === selectedPaymentMethod)?.type;

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="blue" disabled={!a.billing.defaultPaymentMethod}>
          {a.billing.subscription.cancelledAt ? (
            'Activate Subscription'
          ) : (
            <>
              <Icon name="plus" /> Make Payment to Activate Subscription
            </>
          )}
        </Button>
      }
    >
      <Modal.Header>Subscription Payment</Modal.Header>
      <Modal.Content>
        {apiMessage?.success === true ? (
          <>
            <ApiMessage data={apiMessage} />
            <Button type="button" color="blue" onClick={onClose} fluid>
              Done
            </Button>
          </>
        ) : (
          <>
            <Form>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={1}>Amount</Table.Cell>
                    <Table.Cell width={1} textAlign="right">
                      {subtotal.toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>Processing Fee</Table.Cell>
                    <Table.Cell width={1} textAlign="right">
                      {fees.toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>Total</Table.Cell>
                    <Table.Cell width={1} textAlign="right">
                      <strong>{total.toFixed(2)}</strong>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Form.Field disabled={subtotal < 500}>
                <label>Select a payment method</label>
                <div style={{ position: 'relative', marginBottom: '1rem', minHeight: '4rem' }}>
                  {paymentMethodsLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                      <Loader size="tiny" inline />
                    </div>
                  ) : !paymentMethods || paymentMethods?.length === 0 ? (
                    <Message error visible>
                      <Message.Header>No Payment Methods Found</Message.Header>
                      <Message.Content>Please add a payment method on the previous screen to continue.</Message.Content>
                    </Message>
                  ) : (
                    <Grid columns={2}>
                      <Grid.Row>
                        {paymentMethods?.map(pm => (
                          <Grid.Column key={pm.id}>
                            <PaymentMethod
                              paymentMethod={pm}
                              onClick={selectPaymentMethod(pm.id === selectedPaymentMethod ? '' : pm.id)}
                              selected={pm.id === selectedPaymentMethod}
                            />
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  )}
                </div>
              </Form.Field>

              <ApiMessage data={apiMessage} />

              <Elements stripe={stripePromise}>
                <PaymentButton
                  fluid
                  disabled={subtotal <= 0 || selectedPaymentMethod === ''}
                  clientSecret={clientSecret}
                  paymentMethodId={selectedPaymentMethod}
                  paymentMethodType={selectedPaymentMethodType}
                  loading={intentIsLoading}
                  setApiMessage={setApiMessage}
                  onSubmit={onSubmit}
                  content={total > 0 ? `Authorize Payment of $${total.toFixed(2)}` : 'Authorize Payment'}
                />
              </Elements>
            </Form>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AccountBillingSubscriptionPayment;
