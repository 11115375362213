import styled, { css } from 'styled-components';

export const Center = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

type PageContainerProps = {
  readonly flex?: boolean;
  readonly maxWidth?: 'small' | 'medium' | 'large';
};

export const PageContainer = styled.div<PageContainerProps>`
  padding: 2rem;
  ${p =>
    p.flex &&
    css`
      display: flex;
      flex-direction: column;
    `}

  width: 100%;
  margin: 0 auto;
  ${p =>
    p.maxWidth &&
    css`
      max-width: ${p.maxWidth === 'small' ? '640px' : p.maxWidth === 'medium' ? '960px' : '1280px'};
    `}
`;

export const SegmentHeader = styled.div`
  margin: -1rem -1rem 1rem -1rem;
  padding: 2rem;
  border-bottom: 1px solid ${p => p.theme.borderColor};
`;

export const PageHeader = styled.h2`
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const Subhead = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.1ch;
  margin-bottom: 0.25rem;
  color: ${p => p.theme.gray};
`;

type DividerProps = {
  readonly clear?: boolean;
};

export const HorizontalDivider = styled.div<DividerProps>`
  margin: 2rem 0;
  border-bottom: 1px solid ${p => (p.clear ? 'transparent' : p.theme.borderColor)};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PageLayout = styled.div`
  display: grid;
  grid-template: 'h h' 'm c';
  grid-template-rows: 50px 1fr;
  grid-template-columns: auto 1fr;
  height: 100vh;
  width: 100vw;
`;

export const Main = styled.main`
  position: relative;
  grid-area: c;
  background-color: ${p => p.theme.lightBlue};
  color: ${p => p.theme.black};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Note = styled.p`
  font-size: 0.9rem;
  margin-top: -0.5rem !important;
  color: ${p => p.theme.gray};

  label + & {
    margin-top: 0 !important;
  }

  blockquote {
    margin: 0.25rem 0 0;
    padding: 0.1rem 0 0.1rem 0.5rem;
    border-left: 2px solid #ddd;
  }
`;
