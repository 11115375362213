const theme = {
  borderColor: '#ddd',
  black: '#0b0b0b',
  gray: '#999',
  lightBlue: '#f5f6fa',
  darkBlue: '#1b3756',
  primary: '#596af0',
  primaryHover: '#3f51b5',
  brand: '#3e34ca',

  borderRadius: {
    normal: '4px',
    large: '8px',
  },
};

export default theme;
