import { capitalize } from 'lodash';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Card, Container, Grid, Header, Label } from 'semantic-ui-react';

import { useListAccountsQuery } from 'src/api/accounts';
import { useGetUserProfileQuery, useSwitchAccountMutation } from 'src/api/auth';
import LayoutHeader from 'src/components/Header';
import Loading from 'src/components/Loading';
import { Main, Note, PageContainer, PageHeader, PageLayout } from 'src/styles';
import AddAccount from './AddAccount';

const AccountsList = () => {
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();
  const { data: accounts, isLoading: accountsLoading } = useListAccountsQuery({ limit: 10, offset: 0 });
  const { mutateAsync } = useSwitchAccountMutation();
  const { push } = useHistory();
  const location = useLocation<{ redirect?: string }>();

  const onClickAccount = useCallback(
    (id: string) => async () => {
      try {
        await mutateAsync({ id });
        push(location.state?.redirect || '/');
      } catch (e: any) {}
    },
    [location.state?.redirect, mutateAsync, push]
  );

  if (profileLoading || accountsLoading) return <Loading />;
  if (!profile?.user) return <Redirect to="/auth/login" />;

  return (
    <PageLayout>
      <LayoutHeader />
      <Main>
        <PageContainer>
          <Helmet>
            <title>Accounts | leadguard</title>
          </Helmet>

          {accounts?.data && accounts.data.length === 0 ? (
            <AddAccount />
          ) : (
            <Container>
              <PageHeader>Welcome back {profile?.user?.firstname}!</PageHeader>
              <Header>Choose an Account</Header>
              <Note>Accounts are containers for your certificates</Note>

              <Grid style={{ marginTop: '2rem' }}>
                {accounts?.data.map(a => {
                  const [userAccess] = (a.userAccess || []).filter(ua => ua.userId === profile?.user?.id);

                  return (
                    <Grid.Column key={a.id} width={8}>
                      <Card fluid raised>
                        <Card.Content>
                          <Header as="h3">
                            <button type="button" className="link" onClick={onClickAccount(a.id)}>
                              {a.name}
                            </button>
                          </Header>

                          {userAccess?.roles.map(role => (
                            <div key={role} style={{ marginBottom: '1rem' }}>
                              <Label>
                                <span style={{ marginRight: '0.5rem', opacity: 0.5 }}>Role</span>
                                {capitalize(role)}
                              </Label>
                            </div>
                          ))}

                          <div>
                            <Label>
                              <span style={{ marginRight: '0.5rem', opacity: 0.5 }}>Account ID</span>
                              {a.id}
                            </Label>
                          </div>
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  );
                })}
              </Grid>
            </Container>
          )}
        </PageContainer>
      </Main>
    </PageLayout>
  );
};

export default AccountsList;
