import { Grid, Header } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import AccountBillingAlertsForm from './AccountBillingAlertsForm';
import AccountBillingPaymentMethods from './AccountBillingPaymentMethods';
import MonthlySubscription from './MonthlySubscription';

const AccountBilling = () => {
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();

  if (profileLoading || !profile?.user || !profile?.activeAccount) return null;

  const a = profile.activeAccount;

  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <MonthlySubscription account={a} />
        </Grid.Column>

        <Grid.Column>
          <Header as="h4">Payment Methods</Header>
          <AccountBillingPaymentMethods />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Header as="h4">Alert Email(s)</Header>
          <p>
            If your account balance drops below $100, we will send an email notification to the email(s) listed below.
          </p>

          <AccountBillingAlertsForm account={a} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AccountBilling;
