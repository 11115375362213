import styled from 'styled-components';

export const PlayerContainer = styled.div`
  height: 680px;
  margin: 1rem 0 0;
`;

export const DataLabel = styled.span`
  color: #999;
`;
