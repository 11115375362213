import copy from 'copy-to-clipboard';
import { format } from 'date-fns';
import { Dropdown, Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { useGetUserProfileQuery } from 'src/api/auth';
import { Note, Row } from 'src/styles';
import { Certificate } from 'src/types';

const clientURL = process.env.REACT_APP_CLIENT_URL;

const Avatar = styled.div`
  background: #d8ddfd;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
`;

type Props = {
  cert: Certificate;
};

const CertificateShareTokensList = ({ cert }: Props) => {
  const { data: profile } = useGetUserProfileQuery();
  const account = profile?.activeAccount;

  return (
    <>
      {(cert.shareTokens || []).length > 0 && (
        <>
          <Header>People with access</Header>

          {cert.shareTokens?.map(t => {
            const onClickCopy = () => {
              copy(`${clientURL}/#/certificates/${cert.id}?token=${t.token}`);
            };

            return (
              <Row style={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
                <Row style={{ alignItems: 'center', gap: '0.5rem' }}>
                  <Avatar>
                    <Icon name="user" style={{ margin: 0 }} />
                  </Avatar>
                  <div>
                    <Header as="h4" style={{ marginBottom: '0.5rem' }}>
                      {t.email}
                    </Header>
                    <Note style={{ marginBottom: 0 }}>
                      {t.expiresAt ? format(new Date(t.expiresAt), 'MMMM d, yyyy') : 'No expiration'}
                    </Note>
                  </div>
                </Row>

                <Dropdown
                  button
                  className="icon"
                  floating
                  icon="ellipsis horizontal"
                  direction="left"
                  trigger={<></>}
                  options={[
                    { key: 'copy', icon: 'linkify', text: 'Copy link', onClick: onClickCopy },
                    { key: 'expiration', icon: 'stopwatch', text: 'Add expiration', disabled: true },
                    { key: 'delete', icon: 'trash', text: 'Remove access', disabled: true },
                  ]}
                />
              </Row>
            );
          })}
        </>
      )}

      {account && (
        <>
          <Header>General access</Header>
          <Row style={{ alignItems: 'center', gap: '0.5rem' }}>
            <Avatar>
              <Icon name="building" style={{ margin: 0 }} />
            </Avatar>
            <div>
              <Header as="h4" style={{ marginBottom: '0.5rem' }}>
                {account.name}
              </Header>
              <Note style={{ marginBottom: 0 }}>Anyone in this account can find, view and share</Note>
            </div>
          </Row>
        </>
      )}
    </>
  );
};

export default CertificateShareTokensList;
