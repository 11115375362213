import { eventWithTime } from '@rrweb/types';
import { formatDuration, intervalToDuration } from 'date-fns';
import { Header, Table } from 'semantic-ui-react';

type Props = {
  events: eventWithTime[];
};

const getEventTypeName = (type?: number): string => {
  // export declare enum EventType {
  //   DomContentLoaded = 0,
  //   Load = 1,
  //   FullSnapshot = 2,
  //   IncrementalSnapshot = 3,
  //   Meta = 4,
  //   Custom = 5,
  //   Plugin = 6
  // }
  switch (type) {
    case 0:
      return 'DomContentLoaded';
    case 1:
      return 'Load';
    case 2:
      return 'FullSnapshot';
    case 3:
      return 'IncrementalSnapshot';
    case 4:
      return 'Meta';
    case 5:
      return 'Custom';
    case 6:
      return 'Plugin';
    default:
      return 'Unknown';
  }
};

const getEventSourceName = (source?: number): string => {
  // export declare enum IncrementalSource {
  //   Mutation = 0,
  //   MouseMove = 1,
  //   MouseInteraction = 2,
  //   Scroll = 3,
  //   ViewportResize = 4,
  //   Input = 5,
  //   TouchMove = 6,
  //   MediaInteraction = 7,
  //   StyleSheetRule = 8,
  //   CanvasMutation = 9,
  //   Font = 10,
  //   Log = 11,
  //   Drag = 12,
  //   StyleDeclaration = 13,
  //   Selection = 14,
  //   AdoptedStyleSheet = 15,
  //   CustomElement = 16
  // }

  switch (source) {
    case 0:
      return 'Mutation';
    case 1:
      return 'MouseMove';
    case 2:
      return 'MouseInteraction';
    case 3:
      return 'Scroll';
    case 4:
      return 'ViewportResize';
    case 5:
      return 'Input';
    case 6:
      return 'TouchMove';
    case 7:
      return 'MediaInteraction';
    case 8:
      return 'StyleSheetRule';
    case 9:
      return 'CanvasMutation';
    case 10:
      return 'Font';
    case 11:
      return 'Log';
    case 12:
      return 'Drag';
    case 13:
      return 'StyleDeclaration';
    case 14:
      return 'Selection';
    case 15:
      return 'AdoptedStyleSheet';
    case 16:
      return 'CustomElement';
    default:
      return '';
  }
};

const getEventName = (type?: number, source?: number): string => {
  const typeName = getEventTypeName(type);
  const sourceName = getEventSourceName(source);
  return `${typeName} ${sourceName}`;
};

const TabPaneEvents = ({ events }: Props) => {
  const firstTimestamp = events[0]?.timestamp;

  return (
    <>
      <Header>Playback of the user&apos;s visit to your website.</Header>

      <Table compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Event</Table.HeaderCell>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {events.map((e, i) => {
            return (
              <Table.Row key={e.timestamp}>
                <Table.Cell>{e.type === 3 ? getEventSourceName(e.data.source) : getEventTypeName(e.type)}</Table.Cell>
                <Table.Cell>
                  {formatDuration(intervalToDuration({ start: 0, end: e.timestamp - firstTimestamp })) ||
                    `${e.timestamp - firstTimestamp}ms`}
                </Table.Cell>
                <Table.Cell>{e.type === 3 && e.data.source === 5 ? e.data.text ?? '' : ''}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default TabPaneEvents;
