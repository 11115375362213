// import { useGetUserProfileQuery } from './api/auth';
// import Loading from './components/Loading';
import Routes from './Routes';

const App = () => {
  // const { isLoading } = useGetUserProfileQuery();

  // if (isLoading) {
  //   return <Loading />;
  // }

  // if (!data?.user) {
  //   return <AuthRoutes />;
  // }

  return <Routes />;
};

export default App;
