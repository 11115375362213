import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import PaginatedTable from 'src/components/PaginatedTable';
import { PageContainer, PageHeader } from 'src/styles';
import AdminAccountBalanceTransactionsListBody from './AdminAccountBalanceTransactionsListBody';

const AdminAccountsBillingHistory = () => {
  return (
    <ConsoleLayout hideMenubar>
      <PageContainer>
        <Helmet>
          <title>Billing History - Accounts - Admin | leadguard</title>
        </Helmet>

        <PageHeader>
          <Breadcrumbs crumbs={['Admin', 'Accounts', 'Billing History']} />
        </PageHeader>

        <PaginatedTable
          headers={['Details', 'Created At', 'Amount', '']}
          renderBody={AdminAccountBalanceTransactionsListBody}
        />
      </PageContainer>
    </ConsoleLayout>
  );
};

export default AdminAccountsBillingHistory;
