import { createElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useGetUserProfileQuery } from './api/auth';
import Loading from './components/Loading';
import NotFound from './pages/not-found';
import { AccountPermission } from './types';
import { hasAccountPermission } from './utils';

type Props = RouteProps & {
  permission: AccountPermission;
};

const RestrictedRoute = ({ children, component, permission, ...rest }: Props) => {
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();

  const hasAdminAccess = profile?.user?.role === 'admin';

  const isOnboardingCompleted = Boolean(profile?.activeAccount?.onboardingCompletedAt);

  const hasAccess = hasAccountPermission(profile?.activeAccount, permission) || hasAdminAccess;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (profileLoading) {
          return <Loading />;
        }

        if (typeof profile?.user === 'undefined') {
          return <Redirect to={{ pathname: '/auth/login', state: { redirect: location.pathname } }} />;
        }

        if (!profile.activeAccount) {
          return <Redirect to={{ pathname: '/accounts', state: { redirect: location.pathname } }} />;
        }

        if (!isOnboardingCompleted && !hasAdminAccess) {
          return <Redirect to={{ pathname: '/onboarding/business-profile', state: { redirect: location.pathname } }} />;
        }

        if (!hasAccess) {
          return <NotFound />;
        }

        if (component) {
          return createElement(component, rest);
        }

        return children;
      }}
    />
  );
};

export default RestrictedRoute;
