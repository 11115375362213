import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';

import { AddAccountParams, useAddAccountMutation } from 'src/api/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

type ValidationErrors = {
  name?: string;
};

const AddAccount = () => {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useAddAccountMutation();
  const [formdata, setFormdata] = useState<AddAccountParams>({ name: '' });
  const { push } = useHistory();

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      await mutateAsync(formdata);
      push('/');
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Grid>
      <Grid.Row centered>
        <Grid.Column width={4}>
          <Segment>
            <Header>Create an Account</Header>

            <ApiMessage data={apiMessage} />

            <Form onSubmit={onSubmit}>
              <Form.Input label="Name" error={errors.name} name="name" onChange={onChange} />

              <Form.Button loading={isLoading} color="blue" fluid>
                Create
              </Form.Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AddAccount;
