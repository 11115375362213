import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { Container, Header, Message, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import PaginatedTable from 'src/components/PaginatedTable';
import NotFound from 'src/pages/not-found';
import { PageContainer, PageHeader } from 'src/styles';
import { accountBillingIsCurrent, hasAccountAccessRoles } from 'src/utils';
import AccountBilling from './AccountBilling';
import AccountBillingSubscriptionPaymentsListBody from './AccountBillingSubscriptionPaymentsListBody';

const AccountsBillingHistory = () => {
  const { data, isLoading } = useGetUserProfileQuery();

  if (isLoading) return <Loading />;
  if (!data?.user) return <Redirect to="/auth/login" />;
  if (!data.activeAccount) return <Redirect to="/accounts" />;

  if (!hasAccountAccessRoles(data.user, data.activeAccount, ['owner', 'billing'])) return <NotFound />;

  const a = data.activeAccount;

  return (
    <ConsoleLayout hideMenubar>
      <PageContainer>
        <Helmet>
          <title>Billing - Account | leadguard</title>
        </Helmet>

        <Container>
          <PageHeader>
            <Breadcrumbs crumbs={['Account', 'Billing']} />
          </PageHeader>

          {!accountBillingIsCurrent(a) && (
            <Message error>
              <Message.Header>Monthly Subscription Inactive</Message.Header>
              <Message.Content>
                Please add a valid payment method to your account to continue using all features.
              </Message.Content>
            </Message>
          )}

          <Segment>
            {a.billing.enabled ? (
              <AccountBilling />
            ) : (
              <Message success>
                <Message.Header>🎉 Free Tier Active</Message.Header>
                <Message.Content>No payment required at this time.</Message.Content>
              </Message>
            )}
          </Segment>

          {a.billing.enabled && (
            <>
              <Header as="h4">Subscription Payment History</Header>

              <PaginatedTable
                headers={['Details', 'Created At', 'Amount', 'Status', '']}
                renderBody={AccountBillingSubscriptionPaymentsListBody}
              />
            </>
          )}
        </Container>
      </PageContainer>
    </ConsoleLayout>
  );
};

export default AccountsBillingHistory;
