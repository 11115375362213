import { FC } from 'react';

import { Main, PageLayout } from 'src/styles';
import Header from '../Header';
import Menubar from '../Menubar';
import Support from '../Support';

type Props = {
  hideMenubar?: boolean;
};

const ConsoleLayout: FC<Props> = ({ children, hideMenubar }) => {
  return (
    <PageLayout>
      <Header />
      {!hideMenubar && <Menubar />}
      <Main>
        {children}
        <Support />
      </Main>
    </PageLayout>
  );
};

export default ConsoleLayout;
