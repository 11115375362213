import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { AddAccountInput, useAddAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

type ValidationErrors = {
  name?: string;
};

const AddAccount = () => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { isLoading, mutateAsync: createAccount } = useAddAccountMutation();
  const [formdata, setFormdata] = useState<AddAccountInput>({ name: '' });
  const { push } = useHistory();

  const onClose = useCallback(() => {
    setFormdata({ name: '' });
    setErrors({});
    setOpen(false);
  }, []);

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      const newAccount = await createAccount(formdata);
      if (newAccount) push(`/admin/accounts/${newAccount.id}`);
      // onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button floated="right" color="blue">
          <Icon name="plus" />
          Add Account
        </Button>
      }
    >
      <Modal.Header>Add Account</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input label="Name" error={errors.name} name="name" value={formdata.name} onChange={onChange} />

          <Button loading={isLoading} color="blue" fluid>
            Create
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddAccount;
