import { BusinessProfile } from 'src/types';

export const STEPS = {
  BUSINESS_PROFILE: {
    slug: 'business-profile',
    title: 'Business Profile',
    description: 'Please provide the following details about your business.',
  },
  BUSINESS_CONTACTS: {
    slug: 'business-contacts',
    title: 'Business Contacts',
    description: 'Please provide two sets of contact information for your business.',
  },
  SCHEDULE_INTERVIEW: {
    slug: 'schedule-interview',
    title: 'Schedule Onboarding Video Call',
    description: 'Please schedule time to go over the platform, onboarding process and proof of concept phase.',
  },
  SUBSCRIPTION_PAYMENT: {
    slug: 'subscription-payment',
    title: 'Subscription Payment Authorization',
    description: 'Please provide a valid credit card to activate your account.',
  },
  REVIEW: {
    slug: 'review',
    title: 'Review & Activate Account',
    description: 'To complete the process please review and submit the information you have provided.',
  },
} as const;

export type Step = typeof STEPS[keyof typeof STEPS]['slug'];

export type StepProps = {
  businessProfile: BusinessProfile;
  onBack: () => void;
  onContinue: () => void;
  redirect?: string;
};
