import { format, formatDuration, intervalToDuration } from 'date-fns';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';

import { ListCertificatesFilters, useListCertificatesQuery } from 'src/api/certificates';
import LinkWithDetail from 'src/components/LinkWithDetail';
import { RenderProps } from 'src/components/PaginatedTable';
import { Note } from 'src/styles';
import DeleteCertificate from './DeleteCertificate';

type Props = RenderProps & {
  filters?: ListCertificatesFilters;
};

const CertificatesListBody = ({ currentPage, filters, limit, setPageCount }: Props) => {
  const { data: certificates } = useListCertificatesQuery({ limit, offset: limit * (currentPage - 1), ...filters });
  const { push } = useHistory();

  useEffect(() => {
    let count = 1;
    if (typeof certificates !== 'undefined') {
      count = Math.ceil(certificates.total / limit);
    }
    setPageCount(count);
  }, [certificates, limit, setPageCount]);

  return (
    <>
      {certificates?.data.map(c => {
        const d = c.form?.cleanData;

        return (
          <Table.Row key={c.id}>
            <Table.Cell>
              <LinkWithDetail
                to={`/certificates/${c.id}`}
                text={format(new Date(c.createdAt), 'MMMM d, yyyy @ h:mm:ss a')}
                detail={c.id}
              />
            </Table.Cell>
            <Table.Cell>
              <strong style={{ display: 'block', marginBottom: '0.25rem', opacity: (!d?.phone && 0.2) || undefined }}>
                {d?.phone || 'Unknown'}
              </strong>
              <Note style={{ marginBottom: 0 }}>
                {d?.firstname} {d?.lastname}
              </Note>
            </Table.Cell>
            <Table.Cell>
              <span>{c.url}</span>
            </Table.Cell>
            <Table.Cell>
              <span>{formatDuration(intervalToDuration({ start: 0, end: c.duration }))}</span>
            </Table.Cell>
            <Table.Cell>
              <span>{c.eventCount}</span>
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <Button icon="eye" onClick={() => push(`/certificates/${c.id}`)} />
              <DeleteCertificate id={c.id} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default CertificatesListBody;
