import { capitalize, isEmpty } from 'lodash';
import { useState } from 'react';
import { Button, CheckboxProps, Form, Grid, InputOnChangeData } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { Account, AccountPermission, AccountPermissions } from 'src/types';

type ValidationErrors = {
  name?: string;
  ownerId?: string;
};

type Props = {
  account: Account;
};

const AdminAccountSettings = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [name, setName] = useState(account.name || '');
  const [permissions, setPermissions] = useState<AccountPermission[]>(account.permissions || []);

  const { isLoading, mutateAsync } = useUpdateAccountMutation();

  const onChangeName = (_e: React.FormEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
    if (typeof value === 'undefined' || typeof value === 'number') return;
    setName(value);
  };

  const onChangePermission = (_e: React.FormEvent<HTMLInputElement>, { checked, value }: CheckboxProps) => {
    if (typeof value === 'undefined' || typeof value === 'number') return;

    setPermissions(prev => {
      let perms = [...prev];

      if (checked) {
        perms.push(value as AccountPermission);
      } else {
        perms = perms.filter(v => v !== value);
      }

      return perms;
    });
  };

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors = {} as ValidationErrors;

    if (name === '') {
      validationErrors.name = 'Name is required';
    }

    setErrors(validationErrors);

    if (!isEmpty(validationErrors)) {
      return;
    }

    try {
      await mutateAsync({ id: account.id, name, permissions });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Form.Input label="Name" name="name" value={name} onChange={onChangeName} error={errors.name} />
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <label>Permissions</label>

              <Row style={{ flexWrap: 'wrap', columnGap: '1rem', rowGap: '0.5rem' }}>
                {AccountPermissions.map(p => (
                  <Form.Checkbox
                    key={p}
                    label={capitalize(p)}
                    value={p}
                    checked={permissions.includes(p)}
                    onChange={onChangePermission}
                  />
                ))}
              </Row>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Button color="blue" loading={isLoading}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminAccountSettings;
