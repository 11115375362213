import styled, { css } from 'styled-components';

import theme from 'src/styles/theme';

export const HeaderContainer = styled.header<{ admin?: boolean }>`
  grid-area: h;
  background-color: ${p => (p.admin ? '#191919' : '#fff')};
  color: ${p => (p.admin ? 'white' : undefined)};
  display: flex;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  z-index: 5000;
  justify-content: space-between;
`;

export const HeaderMenu = styled.div`
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const HeaderMenuItem = styled.div<{ admin?: boolean }>`
  display: flex;
  ${p =>
    p.admin &&
    css`
      border-left: 1px solid rgba(255, 255, 255, 0.15);
    `}

  a,
  button,
  .ui.dropdown {
    background: transparent;
    border: none;
    color: ${p => (p.admin ? 'white' : 'black')};
    cursor: pointer;

    ${p =>
      p.admin &&
      css`
        border-right: 1px solid rgba(255, 255, 255, 0.15);
      `}

    &:hover, &.active {
      background: ${p => (p.admin ? '#333' : '#fafafa')};
      color: ${p => (p.admin ? 'white' : p.theme.primaryHover)};
    }
  }

  a.nav-item {
    flex: 1;
    display: block;
    padding: 1rem;
  }

  .ui.dropdown {
    flex: 1;
    display: block;
    padding: 1rem;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem 0 0;

  img,
  svg {
    display: block;
    height: 20px;
    width: auto;
  }
`;

export const ProfileMenuItem = styled.div`
  display: flex;
  padding-left: 1rem;

  a,
  button,
  .ui.dropdown {
    background: transparent;
    border: none;
    color: black;
    cursor: pointer;

    :hover {
      /* background: #fafafa; */
      /* color: ${p => p.theme.primaryHover}; */
    }
  }

  .ui.dropdown {
    flex: 1;
    display: block;
    padding: 1rem;
  }
`;

export const ProfileButton = styled.div`
  &:before {
    content: '';
    display: block;
    background: ${theme.primary};
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
  }

  &:hover:before {
    background: ${theme.primaryHover};
  }

  span {
    position: relative;
    z-index: 1;
    color: white;
    display: inline-block;
    text-transform: uppercase;
  }
`;
