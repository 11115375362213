import { capitalize, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { InviteUserToAccountInput, useInviteUserToAccountMutation } from 'src/api/admin/accounts-user-invites';
import { apiErrorHandler, ApiMessageData, APIValidationError } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { AccountUserAccessRoles } from 'src/types';

type ValidationErrors = {
  email?: string;
  accountAccessRoles?: string;
};

const getInitialFormdata = (accountId: string): InviteUserToAccountInput => ({
  accountId,
  email: '',
  accountAccessRoles: ['owner'],
});

type Props = {
  accountId: string;
};

const InviteUserToAccount = ({ accountId }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<InviteUserToAccountInput>(() => getInitialFormdata(accountId));
  const { isLoading, mutateAsync: inviteUser } = useInviteUserToAccountMutation();

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setFormdata(getInitialFormdata(accountId));
    setErrors({});
    setOpen(false);
  }, [accountId]);

  const validate = useCallback((input: InviteUserToAccountInput) => {
    const validationErrors: ValidationErrors = {};

    if (input.email === '') {
      validationErrors.email = 'Email is required';
    }
    if (input.accountAccessRoles.length === 0) {
      validationErrors.accountAccessRoles = 'Account Access Role(s) is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = async () => {
    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await inviteUser(formdata);
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);

      if (e.response && e.response.data && e.response.data.errors) {
        const { errors } = e.response.data;

        const validationErrors: ValidationErrors = {};

        errors.forEach(({ field, error }: APIValidationError) => {
          switch (field.toLowerCase()) {
            case 'email':
              validationErrors.email = error;
              break;
          }
        });

        setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="blue">
          <Icon name="plus" />
          Invite User
        </Button>
      }
    >
      <Modal.Header>Invite User to Account</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input label="Email" error={errors.email} name="email" onChange={onChange} />

          <Form.Select
            multiple
            label="Account Access Role(s)"
            options={AccountUserAccessRoles.map(r => ({ key: r, text: capitalize(r), value: r }))}
            value={formdata.accountAccessRoles}
            name="accountAccessRoles"
            error={errors.accountAccessRoles}
            onChange={onChange}
          />

          <Button loading={isLoading} color="blue" fluid>
            Send Invite
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default InviteUserToAccount;
