import 'rrweb-player/dist/style.css';

import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { Icon, Segment, Tab } from 'semantic-ui-react';
import styled from 'styled-components';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useGetCertificateQuery } from 'src/api/certificates';
import BasicLayout from 'src/components/BasicLayout';
import Loading from 'src/components/Loading';
import useSearchQuery from 'src/hooks/useSearchQuery';
import NotFound from 'src/pages/not-found';
import { Note, PageContainer, PageHeader } from 'src/styles';
import ShareCertificateButtons from './ShareCertificateButtons';
import TabPaneDetails from './TabPaneDetails';
import TabPaneEvents from './TabPaneEvents';
import TabPaneReplay from './TabPaneReplay';

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 0.75rem 0.5rem;
  background: rgba(0, 0, 0, 0.03);
`;

const CertificatesView = () => {
  const params = useParams<{ id: string }>();
  const query = useSearchQuery();
  const { data: profile } = useGetUserProfileQuery();
  const { data: cert, isLoading: certLoading } = useGetCertificateQuery(params.id, query.get('token'));

  if (certLoading) return <Loading />;
  // if (!profie?.user) return <Redirect to="/auth/login" />;
  // if (!profie.activeAccount) return <Redirect to="/accounts" />;
  if (!cert) return <NotFound />;

  return (
    <BasicLayout>
      {profile?.user && (
        <Toolbar>
          <Link to="/certificates">
            <Icon name="chevron left" /> Certificates
          </Link>
          <ShareCertificateButtons cert={cert.certificate} />
        </Toolbar>
      )}

      <PageContainer maxWidth="medium">
        <Helmet>
          <title>Certificate of Authenticity | leadguard</title>
        </Helmet>

        <PageHeader>
          <p>Certificate of Authenticity</p>
          <Note>Certificate ID: {cert.certificate.id}</Note>
        </PageHeader>

        <Segment>
          <Tab
            menu={{ secondary: true, pointing: true }}
            renderActiveOnly
            panes={[
              {
                menuItem: 'Details',
                render: () => <TabPaneDetails cert={cert.certificate} />,
              },
              {
                menuItem: 'Replay',
                render: () => <TabPaneReplay events={cert.events} />,
              },
              {
                menuItem: 'Events',
                render: () => <TabPaneEvents events={cert.events} />,
              },
            ]}
          />
        </Segment>
      </PageContainer>
    </BasicLayout>
  );
};

export default CertificatesView;
