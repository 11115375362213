import { Link, NavLink, useLocation } from 'react-router-dom';
import { Icon, Label } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { accountBillingIsCurrent } from 'src/utils';
import AccountDropdown from './AccountDropdown';
import { HeaderContainer, HeaderMenu, HeaderMenuItem, Logo } from './styles';
import UserDropdown from './UserDropdown';

const Header = () => {
  const { pathname } = useLocation();
  const { data: profile } = useGetUserProfileQuery();

  const isAdmin = profile?.user && profile.user.role === 'admin';
  const inAdmin = isAdmin && pathname.startsWith('/admin');

  const a = profile?.activeAccount;
  const billingIssue = a && !accountBillingIsCurrent(a);

  return (
    <HeaderContainer admin={inAdmin}>
      <HeaderMenu>
        <Logo>
          <Link to="/">
            <svg width="246" height="40" viewBox="0 0 246 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g fill={inAdmin ? '#ffffff' : '#1E1E1F'}>
                <path d="M14.2351 28.3154V32H0.177002V8H4.64173V28.3154H14.2351Z" />
                <path d="M20.7164 11.5519V18.2075H29.1148V21.6432H20.7164V28.4315H31.372V32H16.2351V8H31.372V11.5519H20.7164Z" />
                <path d="M48.9073 22.9378L45.9862 14.9544C45.8423 14.6003 45.693 14.1798 45.538 13.6929C45.3831 13.2061 45.2282 12.6805 45.0733 12.1162C44.9295 12.6805 44.7801 13.2116 44.6252 13.7095C44.4703 14.1964 44.3209 14.6224 44.177 14.9876L41.2725 22.9378H48.9073ZM56.7912 32H53.3389C52.9516 32 52.6362 31.9059 52.3928 31.7178C52.1494 31.5187 51.9668 31.2752 51.8451 30.9876L50.0526 26.0913H40.1107L38.3181 30.9876C38.2296 31.242 38.0581 31.4744 37.8036 31.6846C37.5491 31.8949 37.2338 32 36.8575 32H33.3721L42.8161 8H47.3638L56.7912 32Z" />
                <path d="M80.1022 20C80.1022 21.7593 79.809 23.3748 79.2226 24.8465C78.6361 26.3181 77.8118 27.5851 76.7495 28.6473C75.6873 29.7095 74.4093 30.5339 72.9155 31.1203C71.4217 31.7068 69.762 32 67.9362 32H58.791V8H67.9362C69.762 8 71.4217 8.29876 72.9155 8.89627C74.4093 9.48271 75.6873 10.3071 76.7495 11.3693C77.8118 12.4205 78.6361 13.6819 79.2226 15.1535C79.809 16.6252 80.1022 18.2407 80.1022 20ZM75.5213 20C75.5213 18.6833 75.3443 17.5048 74.9902 16.4647C74.6472 15.4136 74.1437 14.5284 73.4798 13.8091C72.827 13.0788 72.0303 12.5201 71.0898 12.1328C70.1603 11.7455 69.1091 11.5519 67.9362 11.5519H63.2723V28.4481H67.9362C69.1091 28.4481 70.1603 28.2545 71.0898 27.8672C72.0303 27.4799 72.827 26.9267 73.4798 26.2075C74.1437 25.4772 74.6472 24.592 74.9902 23.5519C75.3443 22.5007 75.5213 21.3167 75.5213 20Z" />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M92.377 7.64791C93.2659 7.21417 94.5446 6.70507 96.088 6.2062C99.5468 5.08826 103.63 4.24641 106.582 4.2464C109.534 4.24641 113.617 5.08826 117.076 6.20621C118.619 6.70507 119.898 7.21417 120.787 7.64792C121.344 23.0608 113.585 31.7957 106.582 35.3398C99.5789 31.7957 91.8203 23.0608 92.377 7.64791ZM88.238 5.60522C88.3641 3.90672 99.4482 0.000125011 106.582 0.00012207C113.716 0.000125011 124.8 3.90673 124.926 5.60522C126.382 25.2231 115.839 36.2634 106.582 40.0001C97.325 36.2634 86.7816 25.2231 88.238 5.60522Z"
                fill="url(#paint0_linear_21_13)"
              />
              <g fill={inAdmin ? '#ffffff' : '#1E1E1F'}>
                <path d="M245.823 20C245.823 21.7212 245.536 23.3018 244.962 24.7415C244.388 26.1813 243.582 27.4208 242.543 28.4601C241.503 29.4993 240.253 30.3058 238.792 30.8796C237.33 31.4533 235.706 31.7402 233.92 31.7402H224.973V8.25981H233.92C235.706 8.25981 237.33 8.5521 238.792 9.13667C240.253 9.71042 241.503 10.5169 242.543 11.5562C243.582 12.5846 244.388 13.8187 244.962 15.2585C245.536 16.6982 245.823 18.2788 245.823 20ZM241.341 20C241.341 18.7118 241.168 17.5589 240.821 16.5413C240.486 15.5129 239.993 14.6468 239.344 13.9432C238.705 13.2287 237.926 12.682 237.005 12.3031C236.096 11.9242 235.068 11.7348 233.92 11.7348H229.357V28.2652H233.92C235.068 28.2652 236.096 28.0758 237.005 27.6969C237.926 27.318 238.705 26.7767 239.344 26.0731C239.993 25.3586 240.486 24.4926 240.821 23.475C241.168 22.4466 241.341 21.2882 241.341 20Z" />
                <path d="M211.201 19.4317C212.023 19.4317 212.738 19.3288 213.344 19.1231C213.961 18.9175 214.464 18.636 214.854 18.2788C215.255 17.9107 215.552 17.4777 215.747 16.9797C215.942 16.4817 216.04 15.935 216.04 15.3397C216.04 14.1489 215.644 13.2341 214.854 12.5954C214.075 11.9567 212.879 11.6373 211.266 11.6373H208.473V19.4317H211.201ZM222.437 31.7402H218.492C217.745 31.7402 217.203 31.4479 216.868 30.8633L211.931 23.3451C211.747 23.0636 211.542 22.8633 211.314 22.7443C211.098 22.6252 210.773 22.5656 210.34 22.5656H208.473V31.7402H204.104V8.25981H211.266C212.857 8.25981 214.221 8.42761 215.358 8.76319C216.505 9.08796 217.441 9.54804 218.167 10.1434C218.903 10.7388 219.444 11.4533 219.791 12.2869C220.137 13.1096 220.31 14.0189 220.31 15.0149C220.31 15.8051 220.191 16.5521 219.953 17.2558C219.726 17.9594 219.39 18.5981 218.946 19.1719C218.513 19.7456 217.972 20.249 217.322 20.682C216.684 21.115 215.953 21.456 215.13 21.705C215.412 21.8674 215.671 22.0622 215.91 22.2896C216.148 22.5061 216.364 22.7659 216.559 23.069L222.437 31.7402Z" />
                <path d="M193.961 22.8742L191.103 15.0636C190.962 14.7172 190.816 14.3058 190.665 13.8295C190.513 13.3532 190.361 12.839 190.21 12.2869C190.069 12.839 189.923 13.3586 189.771 13.8457C189.62 14.3221 189.474 14.7388 189.333 15.0961L186.491 22.8742H193.961ZM201.674 31.7402H198.296C197.918 31.7402 197.609 31.6482 197.371 31.4641C197.133 31.2693 196.954 31.0311 196.835 30.7497L195.081 25.9594H185.355L183.601 30.7497C183.514 30.9986 183.347 31.226 183.098 31.4317C182.849 31.6373 182.54 31.7402 182.172 31.7402H178.762L188.001 8.25981H192.451L201.674 31.7402Z" />
                <path d="M167.473 28.2165C168.318 28.2165 169.07 28.0758 169.73 27.7943C170.401 27.5129 170.964 27.1177 171.419 26.6089C171.874 26.1001 172.22 25.4831 172.458 24.7578C172.707 24.0325 172.832 23.2206 172.832 22.3221V8.25981H177.2V22.3221C177.2 23.7185 176.972 25.0122 176.518 26.203C176.074 27.3829 175.43 28.406 174.585 29.272C173.752 30.1272 172.734 30.7984 171.533 31.2855C170.331 31.7618 168.978 32 167.473 32C165.958 32 164.599 31.7618 163.397 31.2855C162.196 30.7984 161.173 30.1272 160.328 29.272C159.495 28.406 158.851 27.3829 158.396 26.203C157.952 25.0122 157.73 23.7185 157.73 22.3221V8.25981H162.098V22.3058C162.098 23.2043 162.217 24.0162 162.456 24.7415C162.705 25.4668 163.056 26.0893 163.511 26.6089C163.977 27.1177 164.539 27.5129 165.2 27.7943C165.871 28.0758 166.629 28.2165 167.473 28.2165Z" />
                <path d="M153.814 19.9675V29.4668C152.623 30.3437 151.351 30.9878 149.998 31.3992C148.656 31.7997 147.216 32 145.679 32C143.762 32 142.025 31.7023 140.466 31.1069C138.918 30.5115 137.592 29.6834 136.488 28.6225C135.394 27.5616 134.55 26.295 133.955 24.8227C133.359 23.3505 133.062 21.7429 133.062 20C133.062 18.2355 133.348 16.617 133.922 15.1448C134.496 13.6725 135.308 12.406 136.358 11.3451C137.419 10.2842 138.702 9.46143 140.206 8.87686C141.711 8.29229 143.4 8 145.273 8C146.225 8 147.113 8.07578 147.936 8.22733C148.769 8.37889 149.538 8.58999 150.241 8.86062C150.956 9.12043 151.606 9.43978 152.19 9.81867C152.775 10.1976 153.311 10.6143 153.798 11.069L152.547 13.0501C152.352 13.364 152.098 13.5589 151.784 13.6346C151.47 13.6996 151.129 13.6184 150.761 13.3911C150.404 13.1854 150.047 12.9797 149.689 12.774C149.332 12.5683 148.932 12.3897 148.488 12.2382C148.055 12.0866 147.562 11.9621 147.01 11.8647C146.469 11.7673 145.841 11.7185 145.126 11.7185C143.968 11.7185 142.918 11.9134 141.976 12.3031C141.045 12.6928 140.25 13.2503 139.589 13.9756C138.929 14.7009 138.42 15.5724 138.063 16.59C137.706 17.6076 137.527 18.7442 137.527 20C137.527 21.3424 137.716 22.544 138.095 23.6049C138.485 24.6549 139.026 25.548 139.719 26.2842C140.423 27.0095 141.267 27.567 142.252 27.9567C143.237 28.3356 144.336 28.525 145.549 28.525C146.415 28.525 147.189 28.433 147.871 28.249C148.553 28.065 149.218 27.816 149.868 27.502V23.2476H146.913C146.631 23.2476 146.409 23.1719 146.247 23.0203C146.095 22.8579 146.02 22.6631 146.02 22.4357V19.9675H153.814Z" />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_21_13"
                  x1="88.1023"
                  y1="20.0001"
                  x2="125.062"
                  y2="20.0001"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2B65BB" />
                  <stop offset="1" stopColor="#DD00DB" />
                </linearGradient>
              </defs>
            </svg>
          </Link>
        </Logo>

        {isAdmin && (
          <HeaderMenuItem admin={inAdmin}>
            <Link to={`/admin`} className="nav-item">
              Admin
            </Link>
            {inAdmin && (
              <>
                <NavLink to={`/admin/accounts`} className="nav-item">
                  Accounts
                </NavLink>
                <NavLink to={`/admin/users`} className="nav-item">
                  Users
                </NavLink>
              </>
            )}
          </HeaderMenuItem>
        )}
      </HeaderMenu>

      <HeaderMenu>
        {billingIssue && (
          <Link
            to={`/account/billing`}
            className="item"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 1rem' }}
          >
            <Label color="red">
              <Icon name="warning circle" /> Billing Issue
            </Label>
          </Link>
        )}

        <AccountDropdown admin={inAdmin} />
        <UserDropdown />
      </HeaderMenu>
    </HeaderContainer>
  );
};

export default Header;
