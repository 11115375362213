import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { Container, Grid, Header, Message, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import PaginatedTable from 'src/components/PaginatedTable';
import { PageContainer, PageHeader } from 'src/styles';
import { accountBillingIsCurrent } from 'src/utils';
import AccountGeneralSettings from './AccountGeneralSettings';
import ListAccountApiTokensBody from './ListAccountApiTokensBody';

const AccountSettings = () => {
  const { data, isLoading } = useGetUserProfileQuery();

  if (isLoading) return <Loading />;
  if (!data?.user) return <Redirect to="/auth/login" />;
  if (!data.activeAccount) return <Redirect to="/accounts" />;

  const a = data.activeAccount;

  return (
    <ConsoleLayout hideMenubar>
      <PageContainer>
        <Helmet>
          <title>Settings - Account | leadguard</title>
        </Helmet>

        <Container>
          <PageHeader>
            <Breadcrumbs crumbs={['Account', 'Settings']} />
          </PageHeader>

          {!accountBillingIsCurrent(data.activeAccount) && (
            <Message error>
              <Message.Header>Monthly Subscription Inactive</Message.Header>
              <Message.Content>
                Please add a valid payment method to your account to continue using all features.
              </Message.Content>
            </Message>
          )}

          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Segment>
                  <Header>General</Header>
                  <AccountGeneralSettings account={data.activeAccount} />
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}></Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header>API Tokens</Header>
                  <PaginatedTable
                    headers={['Name', 'Enabled', 'Expires', 'Token', '']}
                    renderBody={props => <ListAccountApiTokensBody accountId={a.id} {...props} />}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </PageContainer>
    </ConsoleLayout>
  );
};

export default AccountSettings;
