import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { hasAccountAccessRoles } from 'src/utils';
import { HeaderMenuItem } from './styles';

type Props = {
  admin?: boolean;
};

const AccountDropdown = ({ admin }: Props) => {
  const { data: profile } = useGetUserProfileQuery();

  if (!profile?.user || !profile?.activeAccount) return null;

  const hasAccessToBilling = hasAccountAccessRoles(profile.user, profile.activeAccount, ['owner', 'billing']);
  const isOnboardingCompleted = Boolean(profile?.activeAccount?.onboardingCompletedAt);

  return (
    <HeaderMenuItem admin={admin}>
      <Dropdown text={profile?.activeAccount?.name || ''} direction="left">
        <Dropdown.Menu>
          <Dropdown.Item disabled>
            <span>
              Active account: <strong>{profile?.activeAccount?.name || ''}</strong>
            </span>
          </Dropdown.Item>

          {!isOnboardingCompleted && (
            <>
              <Link to={`/onboarding`} className="item">
                Onboarding
              </Link>
              <Dropdown.Divider />
            </>
          )}

          <Link to={`/account/settings`} className="item">
            General
          </Link>

          {hasAccessToBilling && (
            <Link to={`/account/billing`} className="item">
              Billing
            </Link>
          )}

          <Dropdown.Divider />

          <Link to="/accounts" className="item">
            Switch Account
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </HeaderMenuItem>
  );
};

export default AccountDropdown;
