import { format } from 'date-fns';
import { SemanticCOLORS } from 'semantic-ui-react';

import { Account, AccountPermission, AccountUserAccessRole, JobStatus, ReportingTimeRange, User } from './types';

export const cleanName = (s: string): string => {
  // special chars
  s = s.replace(/[^\-|/a-zA-Z0-9_ ]+/g, '');

  // non word chars
  s = s.replace(/[-|/]+/g, '_');

  // uppercase first letter of each word
  s = s
    .split(' ')
    .map(p => {
      if (p.length === 0) return p;
      return p[0].toUpperCase() + p.slice(1);
    })
    .join(' ');

  // bad chars
  s = s.replace(/[^a-zA-Z0-9_]+/g, '');

  // underscores
  s = s.replace(/[_]+/g, '_');

  return s;
};

export const cleanPhone = (phone: any): string => {
  if (typeof phone === 'number') phone = String(phone);
  if (typeof phone !== 'string') return '';

  if (phone.indexOf('@') > -1 || phone.indexOf('sip:') > -1) {
    return cleanSipPhone(phone);
  }

  let p = phone;

  if (p.indexOf('.') > -1 && !Number.isNaN(Number(p))) {
    p = p.split('.')[0];
  }

  p = p.replace(/\D+/g, '').replace(/^1/, '');

  if (p.length < 10) {
    p = '';
  }

  p = p.substring(p.length - 10, p.length);

  return p;
};

export const cleanSipPhone = (phone: any): string => {
  if (typeof phone !== 'string') return '';

  let p = phone;

  const parts = p.split('@');
  if (parts.length > 2) return phone;

  // don't cleanPhone() since different sip servers have different rules
  p = parts[0].replace('sip:', '');
  const addr = parts[1] || '';

  return `sip:${p}@${addr}`;
};

const BAD_ZIPS = ['00000', '11111'];

export const cleanZip = (zip: any): string => {
  if (typeof zip === 'number') zip = String(zip);
  if (typeof zip !== 'string') return '';

  let z = zip;

  // canadian zip
  if (/[a-z]{1}[0-9]{1}[a-z]{1} [0-9]{1}[a-z]{1}[0-9]{1}/.test(z)) {
    return '';
  }

  z = z.replace(/[a-zA-Z]+/g, '').trim();

  if (z.indexOf('.') > -1 && !Number.isNaN(Number(z))) {
    z = z.split('.')[0];
  }
  if (z.indexOf('-') > -1) {
    z = z.split('-')[0];
  }
  if (z.indexOf(' ') > -1) {
    z = z.split(' ')[0];
  }

  z = z.replace(/\D+/g, '');

  if (z.length > 0 && z.length < 5) {
    z = '00000' + z;
    z = z.substring(z.length - 5, z.length);
  }

  if (BAD_ZIPS.indexOf(z) > -1) {
    return '';
  }

  return z;
};

export const hasAccountPermission = (
  activeAccount: Account | null | undefined,
  permission: AccountPermission
): boolean => {
  if (typeof activeAccount === 'undefined') return false;
  if (!activeAccount?.permissions) return false;
  if (activeAccount.permissions.length === 0) return false;

  return activeAccount.permissions.indexOf(permission) > -1;
};

export const getJobStatusColor = (status: JobStatus): undefined | SemanticCOLORS => {
  switch (status) {
    case JobStatus.Completed:
    case JobStatus.Ready:
      return 'green';
    case JobStatus.Error:
      return 'red';
    default:
      return undefined;
  }
};

export const accountBillingIsCurrent = (a: Account): boolean => {
  if (!a.billing.enabled) return true;

  return a.billing.subscription.isActive || false;
};

export const hasAccountAccessRoles = (
  user: User,
  activeAccount: Account | undefined,
  roles: AccountUserAccessRole[]
): boolean => {
  if (user.role === 'admin') return true;
  const userAccess = activeAccount?.userAccess?.find(u => u.userId === user.id);
  if (!userAccess) return false;
  return userAccess.roles.some(r => roles.includes(r));
};

export const reportingTimeRangeToDateStrings = (value: ReportingTimeRange): [string, string] => {
  const ONE_DAY_MS = 86400000;

  let startDate = '';
  let endDate = '';
  switch (value) {
    case 'today':
      startDate = endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'yesterday':
      startDate = endDate = format(new Date(Date.now() - ONE_DAY_MS), 'yyyy-MM-dd');
      break;

    case 'this week':
      // startDate = sunday, endDate = today
      startDate = format(new Date(Date.now() - ONE_DAY_MS * new Date().getDay()), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'last week':
      startDate = format(new Date(Date.now() - ONE_DAY_MS * (new Date().getDay() + 7)), 'yyyy-MM-dd');
      endDate = format(new Date(Date.now() - ONE_DAY_MS * new Date().getDay()), 'yyyy-MM-dd');
      break;

    case 'this month':
      startDate = format(new Date().setDate(1), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'last month':
      startDate = format(new Date().setMonth(new Date().getMonth() - 1, 1), 'yyyy-MM-dd');
      endDate = format(new Date(new Date().setMonth(new Date().getMonth(), 0)), 'yyyy-MM-dd');
      break;

    case 'previous month':
      startDate = format(new Date().setMonth(new Date().getMonth() - 2, 1), 'yyyy-MM-dd');
      endDate = format(new Date(new Date().setMonth(new Date().getMonth() - 1, 0)), 'yyyy-MM-dd');
      break;

    case 'last 90 days':
      startDate = format(new Date(Date.now() - ONE_DAY_MS * 90), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'this year':
      startDate = format(new Date().setMonth(0, 1), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
      break;

    case 'last year':
      const lastYear = new Date().getFullYear() - 1;

      startDate = format(new Date().setFullYear(lastYear, 0, 1), 'yyyy-MM-dd');
      endDate = format(new Date().setFullYear(lastYear, 11, 31), 'yyyy-MM-dd');
      break;
  }

  return [startDate, endDate];
};

export const validEmail = (email: string) => {
  // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
  const re =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+`/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+`/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

  return re.test(email);
};
