import { capitalize, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import {
  Button,
  DropdownProps,
  Form,
  Header,
  Icon,
  Input,
  InputOnChangeData,
  Label,
  Loader,
  Segment,
} from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useUpdateBusinessProfileMutation } from 'src/api/onboarding';
import ApiMessage from 'src/components/ApiMessage';
import { Note, Row } from 'src/styles';
import { BusinessProfile } from 'src/types';
import { getCompanyStepValidationErrors, ValidationErrors } from '.';
import { StepBox } from './styles';
import { StepProps, STEPS } from './types';

const httpPrefix = /^http(s?):\/\//;

const BUSINESS_TYPES = [
  'Sole Proprietorship',
  'Partnership',
  'Limited Liability Corporation',
  'Co-operative',
  'Non-profit Corporation',
  'Corporation',
];

const BUSINESS_INDUSTRIES = [
  'AUTOMOTIVE',
  'AGRICULTURE',
  'BANKING',
  'CONSUMER',
  'EDUCATION',
  'ENGINEERING',
  'ENERGY',
  'OIL_AND_GAS',
  'FAST_MOVING_CONSUMER_GOODS',
  'FINANCIAL',
  'FINTECH',
  'FOOD_AND_BEVERAGE',
  'GOVERNMENT',
  'HEALTHCARE',
  'HOSPITALITY',
  'INSURANCE',
  'LEGAL',
  'MANUFACTURING',
  'MEDIA',
  'ONLINE',
  'RAW_MATERIALS',
  'REAL_ESTATE',
  'RELIGION',
  'RETAIL',
  'JEWELRY',
  'TECHNOLOGY',
  'TELECOMMUNICATIONS',
  'TRANSPORTATION',
  'TRAVEL',
  'ELECTRONICS',
  'NOT_FOR_PROFIT',
];

const StepBusinessProfile = ({ businessProfile, onContinue }: StepProps) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();
  const { mutateAsync: updateProfile, isLoading: isUpdateLoading } = useUpdateBusinessProfileMutation();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<BusinessProfile>(businessProfile);

  const onSubmit = useCallback(
    async (force = false) => {
      setApiMessage(undefined);

      const validationErrors = getCompanyStepValidationErrors(formdata) as ValidationErrors;

      setErrors(validationErrors);

      if (!isEmpty(validationErrors) && !force) {
        setApiMessage({ success: false, status: 400, message: 'Please check for validation errors above.' });
        return;
      }

      try {
        await updateProfile({
          accountId: profile?.activeAccount?.id || '',
          businessProfile: {
            ...formdata,
          },
        });
      } catch (e) {
        apiErrorHandler(e, setApiMessage);
        return;
      }

      onContinue();
    },
    [formdata, onContinue, profile?.activeAccount?.id, updateProfile]
  );

  const onBlurWebsiteURL = useCallback(e => {
    const v = e.target.value;
    if (!v || typeof v !== 'string' || httpPrefix.test(v)) return;

    setFormdata(prev => ({
      ...prev,
      company: {
        ...prev.company,
        websiteUrl: `https://${v}`,
      },
    }));
  }, []);

  const onChangeProfile = useCallback(
    (key: keyof typeof formdata) =>
      (_: any, { name, value, checked }: InputOnChangeData | DropdownProps) =>
        setFormdata(prev => {
          if (key === 'stepScheduleSubmittedAt') return prev;
          if (!['company', 'primaryContact', 'secondaryContact'].includes(key)) return prev;

          return {
            ...prev,
            [key]: {
              ...prev[key],
              [name]: checked ?? value,
            },
          };
        }),
    []
  );

  if (profileLoading) return <Loader active />;

  return (
    <Form onSubmit={() => onSubmit()}>
      <StepBox>
        <Header>{STEPS.BUSINESS_PROFILE.title}</Header>
        <Note>{STEPS.BUSINESS_PROFILE.description}</Note>

        <Segment>
          <Form.Group>
            <Form.Input
              width="5"
              name="einNumber"
              label="EIN #"
              error={errors.company?.einNumber}
              value={formdata.company.einNumber}
              onChange={onChangeProfile('company')}
            />
            <Form.Input
              width="11"
              name="name"
              label="Business Name"
              error={errors.company?.name}
              value={formdata.company.name}
              onChange={onChangeProfile('company')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Select
              width="5"
              fluid
              name="type"
              label="Business Type"
              error={errors.company?.type}
              value={formdata.company.type}
              onChange={onChangeProfile('company')}
              options={BUSINESS_TYPES.map(opt => ({
                key: opt,
                value: opt,
                text: opt,
              }))}
            />
            <Form.Select
              width="5"
              fluid
              name="industry"
              label="Business Industry"
              error={errors.company?.industry}
              value={formdata.company.industry}
              onChange={onChangeProfile('company')}
              options={BUSINESS_INDUSTRIES.map(opt => ({
                key: opt,
                value: opt,
                text: capitalize(opt.replace(/_/g, ' ')),
              }))}
            />

            <Form.Field width="6" error={errors.company?.websiteUrl}>
              <label>Website URL</label>
              <Input
                name="websiteUrl"
                placeholder="example.com"
                value={formdata.company.websiteUrl.replace(httpPrefix, '')}
                onChange={onChangeProfile('company')}
                onBlur={onBlurWebsiteURL}
                label="https://"
                error={!!errors.company?.websiteUrl}
              />
              {errors.company?.websiteUrl && (
                <Label prompt pointing>
                  {errors.company?.websiteUrl}
                </Label>
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Input
              width="10"
              name="address1"
              label="Address Line 1"
              error={errors.company?.address1}
              value={formdata.company.address1}
              onChange={onChangeProfile('company')}
            />
            <Form.Input
              width="6"
              name="address2"
              label="Address Line 2"
              error={errors.company?.address2}
              value={formdata.company.address2 || ''}
              onChange={onChangeProfile('company')}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              name="city"
              label="City"
              error={errors.company?.city}
              value={formdata.company.city}
              onChange={onChangeProfile('company')}
            />
            <Form.Input
              name="state"
              label="State"
              error={errors.company?.state}
              value={formdata.company.state}
              onChange={onChangeProfile('company')}
            />
            <Form.Input
              name="zip"
              label="Zip"
              error={errors.company?.zip}
              value={formdata.company.zip}
              onChange={onChangeProfile('company')}
            />
          </Form.Group>
        </Segment>

        <ApiMessage data={apiMessage} />

        <Row style={{ justifyContent: 'flex-end' }}>
          {/* <Button type="button" basic onClick={onBack}>
              <Icon name="chevron left" />
              Back
            </Button> */}

          {!isEmpty(errors) && (
            <Button type="button" color="blue" basic loading={isUpdateLoading} onClick={() => onSubmit(true)}>
              Save and Continue
              <Icon name="chevron right" />
            </Button>
          )}

          <Button color="blue" loading={isUpdateLoading}>
            Continue
            <Icon name="chevron right" />
          </Button>
        </Row>
      </StepBox>

      {/* <h3>Call Branding</h3>

        <Form.Input
          fluid
          name="cnam_display_name"
          label="Display Name"
          error={errors.cnam_display_name}
          value={businessProfile.cnam_display_name}
          onChange={onChange}
        />

        <h3>Voice Integrity</h3>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            name="avg_daily_calls"
            label="Avg. Business Day Call Volume"
            error={errors.avg_daily_calls}
            value={String(businessProfile.avg_daily_calls || '')}
            onChange={onChange}
          />
          <Form.Input
            fluid
            name="employee_count"
            label="Employee Count"
            error={errors.employee_count}
            value={String(businessProfile.employee_count || '')}
            onChange={onChange}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Select
            fluid
            name="use_case"
            label="Use Case"
            error={errors.use_case}
            value={businessProfile.use_case}
            options={UseCaseOptions}
            onChange={onChange}
          />
          <Form.Input
            fluid
            name="notes"
            label="Notes"
            error={errors.notes}
            value={businessProfile.notes}
            onChange={onChange}
          />
        </Form.Group> */}
    </Form>
  );
};

export default StepBusinessProfile;
