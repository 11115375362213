import { useMutation, useQuery, useQueryClient } from 'react-query';

import { AccountPermission } from 'src/types';
import { AccountResponse, AccountsResponse } from '../accounts';
import { http } from '../http-common';

export type AddAccountInput = {
  name: string;
};

export const useAddAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddAccountInput) => {
      const res = await http.post<AccountResponse>('/api/admin/accounts', input);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts']);
      },
    }
  );
};

export type ListAccountsFilters = {
  enabled?: boolean;
  search?: string;
};

export type ListAccountsParams = ListAccountsFilters & {
  limit: number;
  offset: number;
};

export const useListAccountsQuery = (params: ListAccountsParams) => {
  return useQuery(['admin/accounts', params], async () => {
    const res = await http.get<AccountsResponse>('/api/admin/accounts', { params });
    return res.data.accounts;
  });
};

export const useGetAccountQuery = (id: string) => {
  return useQuery(['admin/accounts', id], async () => {
    const res = await http.get<AccountResponse>(`/api/admin/accounts/${id}`);
    return res.data.account;
  });
};

export type UpdateAccountInput = {
  id: string;
  name?: string;
  permissions?: AccountPermission[];
  enabled?: boolean;
  billing_enabled?: boolean;
  billing_rate?: number;
  billing_subscription_day?: number;
};

export const useUpdateAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateAccountInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}`, input);
      return res.data.account;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['admin/accounts']);
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};
