import { uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useShareCertificateMutation } from 'src/api/certificates';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { Certificate } from 'src/types';
import { validEmail } from 'src/utils';
import CertificateShareTokensList from './CertificateShareTokensList';

type Props = {
  cert: Certificate;
  onClickCopy: () => void;
  copied: boolean;
};

const ShareCertificateModal = ({ cert, onClickCopy, copied }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const { mutateAsync, isLoading } = useShareCertificateMutation();

  const onClose = useCallback(() => {
    setOpen(false);
    setEmails([]);
    setErrors({});
  }, []);

  const onAddItem = useCallback((_, { value }) => {
    value = value.trim().toLowerCase();
    if (!validEmail(value)) {
      setErrors({ email: 'Invalid email address' });
      return;
    }
    setErrors({});
    setEmails(prev => uniq([...prev, value]));
  }, []);

  const onChange = useCallback((_, { value }) => {
    setErrors({});
    const emails = (value as string[]).map(v => v.trim().toLowerCase()).filter(validEmail);
    setEmails(emails);
  }, []);

  const onSubmit = useCallback(async () => {
    if (emails.length === 0) return;

    setApiMessage(undefined);

    try {
      await mutateAsync({ certificateId: cert.id, emails });
      setEmails([]);
      setErrors({});
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [cert.id, emails, mutateAsync]);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
      size="tiny"
      trigger={
        <Button>
          <Icon name="user plus" /> Share
        </Button>
      }
    >
      <Modal.Header>Share Certificate</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Select
            fluid
            multiple
            name="emails"
            error={!!errors.email}
            noResultsMessage={errors.email ? 'Please enter a valid email' : 'Add people by email'}
            placeholder={focused ? '' : 'Add people by email'}
            options={emails.map(email => ({ key: email, text: email, value: email }))}
            onAddItem={onAddItem}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            allowAdditions
            clearable
            search
            onSearchChange={() => setErrors({})}
            value={emails}
            onChange={onChange}
          />

          {emails.length > 0 ? (
            <Form.TextArea placeholder="Message (optional)" />
          ) : (
            <CertificateShareTokensList cert={cert} />
          )}

          <Row style={{ justifyContent: 'space-between', marginTop: '2rem' }}>
            <Button icon={emails.length > 0} type="button" basic onClick={onClickCopy}>
              {copied ? (
                <>
                  <Icon name="check" color="green" /> Copied!
                </>
              ) : (
                <>
                  <Icon name="linkify" /> {emails.length > 0 ? '' : 'Copy link'}
                </>
              )}
            </Button>

            <div>
              {emails.length === 0 ? (
                <Button type="button" color="blue" onClick={onClose}>
                  Done
                </Button>
              ) : (
                <>
                  <Button type="button" onClick={() => setEmails([])}>
                    Cancel
                  </Button>
                  <Button type="submit" color="blue" content="Send" loading={isLoading} />
                </>
              )}
            </div>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ShareCertificateModal;
