export type Account = {
  id: string;
  createdAt: string;
  enabled: boolean;
  name: string;
  billing: Billing;
  userAccess: AccountUserAccess[] | null;
  userInvites: AdminUserInvite[] | null;
  permissions: AccountPermission[] | null;
  onboardingCompletedAt: string | null;
  businessProfile: BusinessProfile | null;
};

export type AccountApiToken = {
  id: number;
  enabled: boolean;
  expiresAt?: string;
  name?: string;
  token: string;
};

export const AccountPermissions = ['CERTIFICATES'] as const;

export type AccountPermission = typeof AccountPermissions[number];

export const AccountUserAccessRoles = ['owner', 'billing', 'user'] as const;

export type AccountUserAccessRole = typeof AccountUserAccessRoles[number];

export type AccountUserAccess = {
  accountId: string;
  addedAt: string;
  addedBy: User | null;
  enabled: boolean;
  roles: AccountUserAccessRole[];
  userId: string;
  user: User | null;
};

export type AdminUserInvite = UserInvite & {
  id: string;
  accountId: string | null;
  account: Account | null;
  accountAccessRoles: AccountUserAccessRole[] | null;
  role: string;
  createdAt: string;
  createdById: string;
  createdBy: User | null;
};

export type Billing = {
  stripeCustomerID: string | null;
  defaultPaymentMethod: string | null;
  enabled: boolean;
  rate: number | null;
  subscription: BillingSubscription;
  alertEmails: string | null;
};

export type BillingBankAccount = {
  id: string;
  accountType: string;
  bankName: string;
  last4: string;
};

export type BillingCard = {
  id: string;
  name: string;
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
};

export enum BillingPaymentIntentAction {
  'add-funds' = 'add-funds',
  'subscribe' = 'subscribe',
}

export type BillingPaymentMethod = {
  id: string;
  isDefault: boolean;
  type: 'card' | 'bank_account';
  card?: BillingCard;
  bankAccount?: BillingBankAccount;
};

export type BillingSubscription = {
  isActive: boolean | null;
  day: number | null;
  cancelledAt: string | null;
};

export type BillingSubscriptionPayment = {
  id: string;
  createdAt: string;
  account: Account | null;
  amount: number;
  status: string;
  payload: any;
};

export type BusinessProfile = {
  company: BusinessProfileCompany;
  primaryContact: BusinessProfileContact;
  secondaryContact: BusinessProfileContact;
  stepScheduleSubmittedAt: string | null;
  // CNAM Registration
  // cnam_display_name: string;
  // Voice Integrity
  // use_case: string;
  // avg_daily_calls: number;
  // employee_count: number;
  // notes: string;
};

export type BusinessProfileCompany = {
  einNumber: string;
  name: string;
  type: string;
  industry: string;
  websiteUrl: string;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zip: string;
};

export type BusinessProfileContact = {
  firstname: string;
  lastname: string;
  jobTitle: string;
  jobPosition: string;
  phone: string;
  email: string;
};

export const newBusinessProfile = (): BusinessProfile => ({
  company: {
    einNumber: '',
    name: '',
    type: '',
    industry: '',
    websiteUrl: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  primaryContact: {
    firstname: '',
    lastname: '',
    jobTitle: '',
    jobPosition: '',
    phone: '',
    email: '',
  },
  secondaryContact: {
    firstname: '',
    lastname: '',
    jobTitle: '',
    jobPosition: '',
    phone: '',
    email: '',
  },
  stepScheduleSubmittedAt: null,
});

export type Certificate = {
  id: string;
  createdAt: string;
  updatedAt: string;
  startTime: number;
  endTime: number;
  duration: number;
  eventCount: number;
  url: string;
  ipAddress: string;
  geoLocation: GeoLocation | null;
  rawUserAgent: string;
  userAgent: UserAgent | null;
  consentLanguage: string;
  shareTokens: CertificateShareToken[] | null;
  form: CertificateForm | null;
};

export type CertificateForm = {
  fields: CertificateFormField[];
  rawData: Record<string, string>;
  cleanData: Record<string, string>;
};

export type CertificateFormField = {
  id: number;
  name: string;
  type: string;
  tagName: string;
};

export type CertificateShareToken = {
  id: string;
  certificateId: string;
  createdAt: string;
  expiresAt: string | null;
  createdById: string;
  createdBy: User | null;
  token: string;
  email: string;
};

export const Delimiters = ['Comma', 'Pipe', 'Tab'] as const;

export type Delimiter = typeof Delimiters[number];

export type GeoLocation = {
  city: string;
  continentName: string;
  continentCode: string;
  countryName: string;
  countryCode: string;
  subdivisionName: string;
  subdivisionCode: string;
  postalCode: string;
  timeZone: string;
  latitude: number;
  longitude: number;
  metroCode: number;
  accuracyRadius: number;
  isAnonymousProxy: boolean;
  isAnycast: boolean;
  isSatelliteProvider: boolean;
};

export type Job = {
  id: number;
  objectid?: number;
  stage: number; // 1, 2 or 3
  status: number;
  queued: string | null; // YYYY-MM-DD HH:ii:ss
  started: string | null; // YYYY-MM-DD HH:ii:ss
  stopped: string | null; // YYYY-MM-DD HH:ii:ss
  heartbeat: string | null; // YYYY-MM-DD HH:ii:ss
  progress1: number;
  progress2: number;
  progress3: number;
  payload: JobPayload;
  type: JobType;
};

export type JobPayload = {
  error?: string;
  message?: string;
};

export enum JobStatus {
  'Pending' = 1,
  'Processing' = 2,
  'Ready' = 3,
  'Stopping' = 4,
  'Error' = 5,
  'Completed' = 6,
  'Paused' = 7,
  'Cancelled' = 8,
  'Deleted' = 9,
  'Processing Externally' = 10,
  'Requires Payment' = 11,
  'Preprocessing' = 12,
}

export enum JobType {
  'DatasetUpload' = 1,
  'Append' = 2,
  'Export' = 3,
  'Render' = 4,
  'Import Model' = 5,
  'Ingest File' = 6,
  'Ingest Dataset' = 7,
  'Convoso Upload File' = 8,
  'Predict' = 9,
  'Dataflow' = 10,
  'Pipeline' = 11,
  'TwilioIncomingPhonesRefetch' = 12,
  'TwilioIncomingPhonesReassignTrustProfiles' = 13,
  'TwilioCallQueueCancelAll' = 14,
  'ReassignTwilioVoiceConfig' = 15,
  'DatasetDelete' = 16,
  'AgentaiCampaignsRefetch' = 17,
  'DatasetsV2Upload' = 18,
  'DatasetsV2Delete' = 19,
  'DatasetsV2ListDelete' = 20,
  'BlacklistIngest' = 21,
}

export type PaginatedResponse<T> = {
  offset: number;
  limit: number;
  count: number;
  total: number;
  data: T[];
};

export const SortDirections = ['ASC', 'DESC'] as const;

export type SortDirection = typeof SortDirections[number];

export const SortDirectionNames = ['Ascending', 'Descending'] as const;

export const ReportingTimeRanges = [
  'today',
  'yesterday',
  'this week',
  'last week',
  'this month',
  'last month',
  'previous month',
  'last 90 days',
  'this year',
  'last year',
  'custom',
] as const;

export type ReportingTimeRange = typeof ReportingTimeRanges[number];

export type UserAgent = {
  VersionNo: UserAgentVersionNumber;
  OSVersionNo: UserAgentVersionNumber;
  URL: string;
  String: string;
  Name: string;
  Version: string;
  OS: string;
  OSVersion: string;
  Device: string;
  Mobile: boolean;
  Tablet: boolean;
  Desktop: boolean;
  Bot: boolean;
};

export type UserAgentVersionNumber = {
  Major: number;
  Minor: number;
  Patch: number;
};

export const UserRoles = [
  { value: 'admin', text: 'Admin' },
  { value: 'user', text: 'User' },
] as const;

export type UserRole = typeof UserRoles[number]['value'];

export type User = {
  id: string;
  email: string;
  role: UserRole | '';
  firstname?: string;
  lastname?: string;
  // tos_accepted_at?: string;
  enabled: boolean;
  // accounts: Account[];
  lastLogin: UserLogin | null;
  // logins: UserLogin[] | null;
};

export type UserInvite = {
  email: string;
  expiresAt: string;
  token: string;
};

export type UserLogin = {
  id: string;
  userId: string;
  user: User | null;
  createdAt: string;
  ipAddress: string;
};

export enum Weekday {
  'Sunday' = 0,
  'Monday' = 1,
  'Tuesday' = 2,
  'Wednesday' = 3,
  'Thursday' = 4,
  'Friday' = 5,
  'Saturday' = 6,
}

export const WeekdayLongNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

export type WeekdayLongName = typeof WeekdayLongNames[number];

export const WeekdayShortNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

export type WeekdayShortName = typeof WeekdayShortNames[number];
