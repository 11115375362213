import { Link } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { Note, Row } from 'src/styles';
import { isCompanyStepValid } from '.';
import { StepBox } from './styles';
import { STEPS } from './types';

const StepBusinessProfileBox = () => {
  const { data: profile } = useGetUserProfileQuery();
  const isValid = isCompanyStepValid(profile?.activeAccount?.businessProfile);

  return (
    <Link to={`/onboarding/${STEPS.BUSINESS_PROFILE.slug}`}>
      <StepBox>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Header>{STEPS.BUSINESS_PROFILE.title}</Header>
            <Note>{STEPS.BUSINESS_PROFILE.description}</Note>
          </div>

          <Icon
            title={isValid ? 'Completed' : 'Incomplete'}
            name={isValid ? 'check' : 'ellipsis horizontal'}
            size="big"
            color={isValid ? 'green' : 'grey'}
          />
        </Row>
      </StepBox>
    </Link>
  );
};

export default StepBusinessProfileBox;
