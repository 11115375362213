import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import AccountBillingAlertsForm from 'src/pages/console/account/billing/AccountBillingAlertsForm';
import theme from 'src/styles/theme';
import { Account } from 'src/types';

type ValidationErrors = {
  billing_rate?: string;
  billing_subscription_day?: string;
};

type BillingFormdata = {
  billing_rate: string;
  billing_subscription_day: string;
};

const getInitialFormdata = (a: Account): BillingFormdata => {
  return {
    billing_rate: String(a.billing.rate || 1500),
    billing_subscription_day: String(a.billing.subscription.day || 1),
  };
};

type Props = {
  account: Account;
};

const AdminAccountBilling = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<BillingFormdata>(() => getInitialFormdata(account));
  const { isLoading, mutateAsync } = useUpdateAccountMutation();

  const onChange = ({ name, value }: { name: string; value: string }) => {
    setFormdata(prev => ({ ...prev, [name]: String(value) }));
  };

  const onSubmit = async () => {
    const validationErrors: ValidationErrors = {};

    if (formdata.billing_rate === '') {
      validationErrors.billing_rate = 'required';
    }

    if (formdata.billing_subscription_day === '') {
      validationErrors.billing_subscription_day = 'required';
    }
    if (Number.isNaN(Number(formdata.billing_subscription_day))) {
      validationErrors.billing_subscription_day = 'must be a number';
    }
    if (Number(formdata.billing_subscription_day) < 1) {
      validationErrors.billing_subscription_day = 'must be greater than 0';
    }
    if (Number(formdata.billing_subscription_day) > 28) {
      validationErrors.billing_subscription_day = 'must be less than 29';
    }

    setErrors(validationErrors);
    if (!isEmpty(validationErrors)) {
      return;
    }

    try {
      await mutateAsync({
        id: account.id,
        billing_rate: Number(formdata.billing_rate),
        billing_subscription_day: Number(formdata.billing_subscription_day),
      });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column>
          <Form onSubmit={onSubmit}>
            <Header as="h4">Settings</Header>

            <ApiMessage data={apiMessage} />

            <Form.Input
              label={
                <label>
                  Rate <span style={{ color: theme.gray }}>(in dollars)</span>
                </label>
              }
              name="billing_rate"
              value={formdata.billing_rate}
              onChange={(_, { name, value }) => onChange({ name, value })}
              error={errors.billing_rate}
            />

            <Form.Group widths="equal">
              <Form.Input
                fluid
                width={6}
                label="Day of the Month"
                name="billing_subscription_day"
                value={formdata.billing_subscription_day}
                onChange={(_, { name, value }) => onChange({ name, value })}
                error={errors.billing_subscription_day}
              />
            </Form.Group>

            <Button color="blue" loading={isLoading}>
              Save
            </Button>
          </Form>
        </Grid.Column>

        <Grid.Column>
          <Header as="h4">Alert Email(s)</Header>

          <p>If your monthly subscription payment fails we will send a notification to the email(s) listed below.</p>

          <AccountBillingAlertsForm account={account} />
        </Grid.Column>

        <Grid.Column>
          <Header as="h4">Subscription</Header>

          <Link to={`/admin/accounts/${account.id}/billing-history`}>
            <Button as="span" color="blue" compact>
              <Icon name="list ul" /> History
            </Button>
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AdminAccountBilling;
