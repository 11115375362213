import { useMutation, useQueryClient } from 'react-query';

import { AccountUserAccessRole } from 'src/types';
import { UserInviteResponse } from '../auth';
import { http } from '../http-common';

export type InviteUserToAccountInput = {
  accountId: string;
  email: string;
  accountAccessRoles: AccountUserAccessRole[];
};

export const useInviteUserToAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, ...input }: InviteUserToAccountInput) => {
      const res = await http.post<UserInviteResponse>(`/api/admin/accounts/${accountId}/user-invites`, input);
      return res.data;
    },
    {
      onSuccess: (_, { accountId }) => {
        queryClient.invalidateQueries(['admin/accounts', accountId]);
      },
    }
  );
};
