import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { hasAccountPermission } from 'src/utils';
import { MenubarItem, MenubarSpacer, Nav, NavContainer } from './styles';

const MENUBAR_KEY = 'menubar-collapsed';

const useMenubarState = () => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const isCollapsed = localStorage.getItem(MENUBAR_KEY);
    return isCollapsed === 'true';
  });

  const toggleIsCollapsed = useCallback(() => {
    setIsCollapsed(collapsed => {
      localStorage.setItem(MENUBAR_KEY, JSON.stringify(!collapsed));
      return !collapsed;
    });
  }, [setIsCollapsed]);

  return {
    isCollapsed,
    toggleIsCollapsed,
  };
};

const Menubar = () => {
  const { data } = useGetUserProfileQuery();
  const { isCollapsed, toggleIsCollapsed } = useMenubarState();

  const isAdmin = data?.user?.role === 'admin';

  const billingEnabled = data?.activeAccount && data?.activeAccount.billing.enabled;

  return (
    <NavContainer collapsed={isCollapsed}>
      <Nav collapsed={isCollapsed} className={isCollapsed ? 'collapsed' : ''}>
        <MenubarItem>
          <NavLink to="/welcome">
            <Icon name="home" />
            <span>Welcome</span>
          </NavLink>
        </MenubarItem>

        {(hasAccountPermission(data?.activeAccount, 'CERTIFICATES') || isAdmin) && (
          <>
            <MenubarItem>
              <NavLink to="/certificates">
                <Icon name="certificate" />
                <span>Certificates</span>
              </NavLink>
            </MenubarItem>

            <MenubarItem>
              <NavLink to="/demo-form">
                <Icon name="list alternate" />
                <span>Demo Form</span>
              </NavLink>
            </MenubarItem>
          </>
        )}

        <MenubarSpacer />

        {billingEnabled && (
          <MenubarItem>
            <NavLink to="/account/billing">
              <Icon name="money bill alternate outline" />
              <span>Billing</span>
            </NavLink>
          </MenubarItem>
        )}

        <MenubarItem>
          <NavLink to="#" isActive={() => false} onClick={toggleIsCollapsed}>
            <Icon name={`chevron ${isCollapsed ? 'right' : 'left'}`} />
            <span>{isCollapsed ? 'Expand' : 'Collapse'}</span>
          </NavLink>
        </MenubarItem>
      </Nav>
    </NavContainer>
  );
};

export default Menubar;
