import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Grid, Header, Label, Segment } from 'semantic-ui-react';

import { useGetUserQuery } from 'src/api/admin/users';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { PageContainer, PageHeader } from 'src/styles';
import AdminUserSettings from './AdminUserSettings';

const AdminUsersEdit = () => {
  const params = useParams<{ id: string }>();
  const { data: user, isLoading } = useGetUserQuery(params.id);

  if (isLoading) return <Loading />;
  if (!user) return <NotFound />;

  return (
    <ConsoleLayout hideMenubar>
      <PageContainer>
        <Helmet>
          <title>Edit - Users - Admin | leadguard</title>
        </Helmet>

        <PageHeader>
          <Breadcrumbs crumbs={['Admin', 'Users', 'Edit']} />
        </PageHeader>

        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header>General Settings</Header>
                <Label>{user.email}</Label>
              </Grid.Column>
              <Grid.Column width={12}>
                <AdminUserSettings user={user} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </PageContainer>
    </ConsoleLayout>
  );
};

export default AdminUsersEdit;
