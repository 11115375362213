import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, DropdownProps, Form, Header, Icon, InputOnChangeData, Loader, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useUpdateBusinessProfileMutation } from 'src/api/onboarding';
import ApiMessage from 'src/components/ApiMessage';
import { Note, Row } from 'src/styles';
import { BusinessProfile } from 'src/types';
import { getContactStepValidationErrors, ValidationErrors } from '.';
import { StepBox } from './styles';
import { StepProps, STEPS } from './types';

const JOB_POSITIONS = ['Director', 'GM', 'VP', 'CEO', 'CFO', 'General Counsel', 'Other'];

const StepBusinessContacts = ({ businessProfile, onBack, onContinue }: StepProps) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();
  const { mutateAsync: updateProfile, isLoading: isUpdateLoading } = useUpdateBusinessProfileMutation();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<BusinessProfile>(businessProfile);

  // NOTE: We need this useEffect() to update the businessProfile.company state so we
  // don't lose it when this form is submitted. The initial render of this component
  // will NOT have the company data from the previous step until after it has been
  // re-fetched from the API due to the query being invalidated by the previous mutation.
  useEffect(() => setFormdata(businessProfile), [businessProfile]);

  const onSubmit = useCallback(
    async (force = false) => {
      setApiMessage(undefined);

      const validationErrors = getContactStepValidationErrors(formdata) as ValidationErrors;

      setErrors(validationErrors);

      if (!isEmpty(validationErrors) && !force) {
        setApiMessage({ success: false, status: 400, message: 'Please check for validation errors above.' });
        return;
      }

      try {
        await updateProfile({
          accountId: profile?.activeAccount?.id || '',
          businessProfile: formdata,
        });
      } catch (e) {
        apiErrorHandler(e, setApiMessage);
        return;
      }

      onContinue();
    },
    [formdata, onContinue, profile?.activeAccount?.id, updateProfile]
  );

  const onChangeProfile = useCallback(
    (key: keyof typeof formdata) =>
      (_: any, { name, value, checked }: InputOnChangeData | DropdownProps) =>
        setFormdata(prev => {
          if (key === 'stepScheduleSubmittedAt') return prev;
          if (!['company', 'primaryContact', 'secondaryContact'].includes(key)) return prev;

          return {
            ...prev,
            [key]: {
              ...prev[key],
              [name]: checked ?? value,
            },
          };
        }),
    []
  );

  if (profileLoading) return <Loader active />;

  return (
    <Form onSubmit={() => onSubmit()}>
      <StepBox>
        <Header>{STEPS.BUSINESS_CONTACTS.title}</Header>
        <Note>{STEPS.BUSINESS_CONTACTS.description}</Note>

        <Segment>
          <Header as="h5" color="grey">
            Primary Contact
          </Header>

          <Form.Group widths="equal">
            <Form.Input
              name="firstname"
              label="First Name"
              error={errors.primaryContact?.firstname}
              value={formdata.primaryContact.firstname}
              onChange={onChangeProfile('primaryContact')}
            />
            <Form.Input
              name="lastname"
              label="Last Name"
              error={errors.primaryContact?.lastname}
              value={formdata.primaryContact.lastname}
              onChange={onChangeProfile('primaryContact')}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              name="jobTitle"
              label="Job Title"
              error={errors.primaryContact?.jobTitle}
              value={formdata.primaryContact.jobTitle}
              onChange={onChangeProfile('primaryContact')}
            />
            <Form.Select
              fluid
              name="jobPosition"
              label="Job Position"
              error={errors.primaryContact?.jobPosition}
              value={formdata.primaryContact.jobPosition}
              onChange={onChangeProfile('primaryContact')}
              options={JOB_POSITIONS.map(opt => ({
                key: opt,
                value: opt,
                text: opt,
              }))}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              name="phone"
              label="Phone"
              error={errors.primaryContact?.phone}
              value={formdata.primaryContact.phone}
              onChange={onChangeProfile('primaryContact')}
            />
            <Form.Input
              name="email"
              label="Email"
              error={errors.primaryContact?.email}
              value={formdata.primaryContact.email}
              onChange={onChangeProfile('primaryContact')}
            />
          </Form.Group>
        </Segment>

        <Segment>
          <Header as="h5" color="grey">
            Secondary Contact
          </Header>

          <Form.Group widths="equal">
            <Form.Input
              name="firstname"
              label="First Name"
              error={errors.secondaryContact?.firstname}
              value={formdata.secondaryContact.firstname}
              onChange={onChangeProfile('secondaryContact')}
            />
            <Form.Input
              name="lastname"
              label="Last Name"
              error={errors.secondaryContact?.lastname}
              value={formdata.secondaryContact.lastname}
              onChange={onChangeProfile('secondaryContact')}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              name="jobTitle"
              label="Job Title"
              error={errors.secondaryContact?.jobTitle}
              value={formdata.secondaryContact.jobTitle || ''}
              onChange={onChangeProfile('secondaryContact')}
            />
            <Form.Select
              fluid
              name="jobPosition"
              label="Job Position"
              error={errors.secondaryContact?.jobPosition}
              value={formdata.secondaryContact.jobPosition}
              onChange={onChangeProfile('secondaryContact')}
              options={JOB_POSITIONS.map(opt => ({
                key: opt,
                value: opt,
                text: opt,
              }))}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              name="phone"
              label="Phone"
              error={errors.secondaryContact?.phone}
              value={formdata.secondaryContact.phone}
              onChange={onChangeProfile('secondaryContact')}
            />
            <Form.Input
              name="email"
              label="Email"
              error={errors.secondaryContact?.email}
              value={formdata.secondaryContact.email}
              onChange={onChangeProfile('secondaryContact')}
            />
          </Form.Group>
        </Segment>

        <ApiMessage data={apiMessage} />

        <Row style={{ justifyContent: 'flex-end' }}>
          <Button type="button" basic onClick={onBack}>
            <Icon name="chevron left" />
            Back
          </Button>

          {!isEmpty(errors) && (
            <Button type="button" color="blue" basic loading={isUpdateLoading} onClick={() => onSubmit(true)}>
              Save and Continue
              <Icon name="chevron right" />
            </Button>
          )}

          <Button color="blue" loading={isUpdateLoading}>
            Continue
            <Icon name="chevron right" />
          </Button>
        </Row>
      </StepBox>
    </Form>
  );
};

export default StepBusinessContacts;
