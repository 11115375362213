import { useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import { useRemoveUserAccessFromAccountMutation } from 'src/api/admin/accounts-user-access';

type Props = {
  accountId: string;
  userId: string;
};

const RemoveUserAccess = ({ accountId, userId }: Props) => {
  const { isLoading, mutateAsync: removeUserAccess } = useRemoveUserAccessFromAccountMutation();

  const onClick = useCallback(async () => {
    await removeUserAccess({ accountId, userId });
  }, [accountId, userId, removeUserAccess]);

  return <Button icon="trash" color="red" onClick={onClick} loading={isLoading} />;
};

export default RemoveUserAccess;
