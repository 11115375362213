import { useMutation, useQuery, useQueryClient } from 'react-query';

import { PaginatedResponse, User } from 'src/types';
import { ProfileResponse } from '../auth';
import { ApiResponse, http } from '../http-common';

export type UsersResponse = ApiResponse & {
  users: PaginatedResponse<User>;
};

export type AddUserInput = {
  email: string;
  firstname?: string;
  lastname?: string;
  role: string;
  password: string;
  confirm: string;
};

export const useAddUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddUserInput) => {
      const res = await http.post<ProfileResponse>('/api/admin/users', input);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/users']);
      },
    }
  );
};

export type ListUsersFilters = {
  enabled?: boolean;
  search?: string;
  selected?: string[];
};

export type ListUsersParams = ListUsersFilters & {
  limit: number;
  offset: number;
};

export const useListUsersQuery = (params: ListUsersParams) => {
  return useQuery(['admin/users', params], async () => {
    const res = await http.get<UsersResponse>('/api/admin/users', { params });
    return res.data.users;
  });
};

export const useGetUserQuery = (id: string) => {
  return useQuery(['admin/users', id], async () => {
    const res = await http.get<ProfileResponse>(`/api/admin/users/${id}`);
    return res.data.user;
  });
};

export type UpdateUserInput = {
  id: string;
  enabled?: boolean;
  // email?: string;
  firstname?: string;
  lastname?: string;
  role?: string;
};

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateUserInput) => {
      const res = await http.post<ProfileResponse>(`/api/admin/users/${id}`, input);
      return res.data.user;
    },
    {
      onSuccess: user => {
        if (!user) return;
        queryClient.removeQueries(['admin/users', user.id]);
        queryClient.invalidateQueries(['admin/users']);
      },
    }
  );
};
