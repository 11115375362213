import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react';

import { Note, Row } from 'src/styles';
import { StepBox } from './styles';
import { STEPS } from './types';

const StepReviewBox = () => {
  // const { data: profile } = useGetUserProfileQuery();
  // const isValid = !!profile?.activeAccount?.billing.defaultPaymentMethod;

  return (
    <Link to={`/onboarding/${STEPS.REVIEW.slug}`}>
      <StepBox>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Header>{STEPS.REVIEW.title}</Header>
            <Note>{STEPS.REVIEW.description}</Note>
          </div>

          {/* <Icon
            title={isValid ? 'Completed' : 'Incomplete'}
            name={isValid ? 'check' : 'ellipsis horizontal'}
            size="big"
            color={isValid ? 'green' : 'grey'}
          /> */}
        </Row>
      </StepBox>
    </Link>
  );
};

export default StepReviewBox;
