import { Redirect, Route, Switch } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import AdminAccountsBillingHistory from './pages/admin/accounts/billing-history';
import AdminAccountsEdit from './pages/admin/accounts/edit';
import AdminAccountsList from './pages/admin/accounts/list';
import AdminUsersEdit from './pages/admin/users/edit';
import AdminUsersList from './pages/admin/users/list';
import AuthAcceptInvite from './pages/auth/accept-invite';
import AuthForgotPassword from './pages/auth/forgot-password';
import AuthLogin from './pages/auth/login';
import AuthResetPassword from './pages/auth/reset-password';
import AccountsBillingHistory from './pages/console/account/billing';
import AccountSettings from './pages/console/account/settings';
import AccountsList from './pages/console/accounts/list';
import CertificatesList from './pages/console/certificates/list';
import CertificatesView from './pages/console/certificates/view';
import DemoForm from './pages/console/demo-form';
import Onboarding from './pages/console/onboarding';
import UserSettings from './pages/console/user/settings';
import Welcome from './pages/console/welcome';
import NotFound from './pages/not-found';
import RestrictedRoute from './RestrictedRoute';

const Routes = () => (
  <Switch>
    {/* Auth */}
    <Route exact path="/auth/accept-invite/:token" component={AuthAcceptInvite} />
    <Route exact path="/auth/forgot-password" component={AuthForgotPassword} />
    <Route exact path="/auth/reset-password/:token" component={AuthResetPassword} />
    <Route exact path="/auth/login" component={AuthLogin} />

    {/* Admin */}
    <AdminRoute exact path="/admin/accounts/:id/billing-history" component={AdminAccountsBillingHistory} />
    <AdminRoute exact path="/admin/accounts/:id" component={AdminAccountsEdit} />
    <AdminRoute exact path="/admin/accounts" component={AdminAccountsList} />
    <AdminRoute exact path="/admin/users/:id" component={AdminUsersEdit} />
    <AdminRoute exact path="/admin/users" component={AdminUsersList} />
    <Redirect exact path="/admin" to="/admin/accounts" />

    {/* Console */}
    <Route exact path="/account/settings" component={AccountSettings} />
    <Route exact path="/account/billing" component={AccountsBillingHistory} />
    <Route exact path="/accounts" component={AccountsList} />
    <Route exact path="/onboarding/:step" component={Onboarding} />
    <Route exact path="/onboarding" component={Onboarding} />
    <Route exact path="/user/settings" component={UserSettings} />

    <Route exact path="/certificates/:id" component={CertificatesView} />
    <RestrictedRoute exact path="/certificates" component={CertificatesList} permission="CERTIFICATES" />
    <RestrictedRoute exact path="/welcome" component={Welcome} permission="CERTIFICATES" />
    <RestrictedRoute exact path="/demo-form" component={DemoForm} permission="CERTIFICATES" />

    <Redirect exact path="/" to="/welcome" />

    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
