import { useEffect } from 'react';
import { Label, Table } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';

type Props = RenderProps & {
  accountId: string;
};

const ListAccountApiTokensBody = ({ accountId, limit, currentPage, setPageCount }: Props) => {
  const { data: apiTokens } = useListAccountApiTokensQuery({
    accountId,
    limit,
    offset: limit * (currentPage - 1),
  });

  useEffect(() => {
    let count = 1;
    if (typeof apiTokens !== 'undefined') {
      count = Math.ceil(apiTokens.total / limit);
    }
    setPageCount(count);
  }, [apiTokens, limit, setPageCount]);

  return (
    <>
      {apiTokens?.data.map(t => (
        <Table.Row key={t.id}>
          <Table.Cell>{t.name || <span style={{ color: theme.gray }}>Not Available</span>}</Table.Cell>
          <Table.Cell>{t.enabled ? <Label color="green">Enabled</Label> : <Label>Disabled</Label>}</Table.Cell>
          <Table.Cell>{t.expiresAt || <span style={{ color: theme.gray }}>Never</span>}</Table.Cell>
          <Table.Cell>{t.token}</Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <Row style={{ justifyContent: 'flex-end' }}></Row>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListAccountApiTokensBody;
