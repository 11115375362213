import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Header, Segment } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useGetUserProfileQuery } from 'src/api/auth';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import { PageContainer, PageHeader } from 'src/styles';

const Welcome = () => {
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();
  const { data: apiTokens, isLoading: apiTokensLoading } = useListAccountApiTokensQuery({
    accountId: profile?.activeAccount?.id || '',
    limit: 1,
    offset: 0,
  });

  if (profileLoading) return <Loading />;
  if (!profile?.user) return <Redirect to="/auth/login" />;
  if (!profile?.activeAccount) return <Redirect to="/accounts" />;
  if (apiTokensLoading) return <Loading />;

  const token = apiTokens?.data[0].token || 'abcdefg123456';

  return (
    <ConsoleLayout>
      <PageContainer>
        <Helmet>
          <title>Welcome | leadguard</title>
        </Helmet>

        <PageHeader>Welcome</PageHeader>

        <Segment>
          <p style={{ margin: 0 }}>Thanks for signing up and welcome to Leadguard!</p>
        </Segment>

        <Segment>
          <Header>Web Snippet</Header>

          <div style={{ maxWidth: '50%' }}>
            <p>
              Leadguard's configurable web snippet allows you to record user sessions and generate certificates for you
              web forms with no extra work. Place the following snippet in your website's HTML, ideally just above the{' '}
              <code className="hl">{`</head>`}</code> tag. You can add it to your entire site or just on the form
              page(s) you'd like to track.
            </p>

            <p>
              Make sure to configure the <code>form_selector</code> below to match your form. If a <code>form</code> is
              not able to be found on the page using the selector, the session recorder will not be started.
            </p>
          </div>

          <SyntaxHighlighter language="xml" style={github} customStyle={{ margin: 0 }}>
            {`<script>
!(function(d,i){var p,r;i.__SV||((window.leadguard=i),(i._i=[]),(i.init=function(t,c){((p = d.createElement('script')).type = 'text/javascript'),(p.async = !0),(p.src = c.api_host + '/dist/recorder.js'),(r = d.getElementsByTagName('script')[0]).parentNode.insertBefore(p, r);i._i.push([t, c]);}),(i.__SV = 1));})(document, window.leadguard || []);
leadguard.init('${token}', { api_host: '${process.env.REACT_APP_SERVER_URL}', form_selector: '.lg-form' });
</script>`}
          </SyntaxHighlighter>
        </Segment>
      </PageContainer>
    </ConsoleLayout>
  );
};

export default Welcome;
