import copy from 'copy-to-clipboard';
import { useCallback, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

import { Certificate } from 'src/types';
import ShareCertificateModal from './ShareCertificateModal';

const clientURL = process.env.REACT_APP_CLIENT_URL;

type Props = {
  cert: Certificate;
};

const ShareCertificateButtons = ({ cert }: Props) => {
  const [copied, setCopied] = useState(false);

  const onClickCopy = useCallback(() => {
    copy(`${clientURL}/#/certificates/${cert.id}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [cert.id]);

  return (
    <Button.Group color="blue">
      <ShareCertificateModal cert={cert} onClickCopy={onClickCopy} copied={copied} />
      <Dropdown
        className="button icon"
        floating
        options={[{ key: 'copy', icon: 'linkify', text: 'Copy Link', onClick: onClickCopy }]}
        trigger={<></>}
      />
    </Button.Group>
  );
};

export default ShareCertificateButtons;
