import styled from 'styled-components';

export const Iframe = styled.iframe`
  border: 0;
  height: 800px;
  width: 100%;
  overflow: auto;
  margin: 1rem 0;
  display: block;
`;

export const StepBox = styled.div`
  padding: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 1rem 0;

  *:last-child {
    margin-bottom: 0 !important;
  }

  a:hover & {
    background: rgba(255, 255, 255, 0.5);
    border-color: #bbb;
  }
`;
