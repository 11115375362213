import { isEmpty } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import LayoutHeader from 'src/components/Header';
import Loading from 'src/components/Loading';
import { Main, PageContainer, PageHeader, PageLayout } from 'src/styles';
import { BusinessProfile, BusinessProfileCompany, BusinessProfileContact, newBusinessProfile } from 'src/types';
import StepBusinessContacts from './StepBusinessContacts';
import StepBusinessContactsBox from './StepBusinessContactsBox';
import StepBusinessProfile from './StepBusinessProfile';
import StepBusinessProfileBox from './StepBusinessProfileBox';
import StepReview from './StepReview';
import StepReviewBox from './StepReviewBox';
import StepSubscriptionPayment from './StepSubscriptionPayment';
import StepSubscriptionPaymentBox from './StepSubscriptionPaymentBox';
import { Step, StepProps, STEPS } from './types';

const REGISTERED_STEPS: {
  slug: Step;
  component: FC<StepProps>;
  link: FC;
}[] = [
  {
    slug: 'business-profile',
    component: StepBusinessProfile,
    link: StepBusinessProfileBox,
  },
  {
    slug: 'business-contacts',
    component: StepBusinessContacts,
    link: StepBusinessContactsBox,
  },
  // {
  //   slug: 'schedule-interview',
  //   component: StepSchedule,
  //   link: StepScheduleBox,
  // },
  {
    slug: 'subscription-payment',
    component: StepSubscriptionPayment,
    link: StepSubscriptionPaymentBox,
  },
  {
    slug: 'review',
    component: StepReview,
    link: StepReviewBox,
  },
];

export type CompanyValidationErrors = {
  einNumber?: string;
  name?: string;
  type?: string;
  industry?: string;
  websiteUrl?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export type ContactValidationErrors = {
  firstname?: string;
  lastname?: string;
  jobTitle?: string;
  jobPosition?: string;
  phone?: string;
  email?: string;
};

export type ValidationErrors = {
  [key: string]: string | ValidationErrors | undefined;
  company?: CompanyValidationErrors;
  primaryContact?: ContactValidationErrors;
  secondaryContact?: ContactValidationErrors;
  // CNAM Registration
  // cnam_display_name?: string;
  // Voice Integrity Trust Product
  // avg_daily_calls?: string;
  // employee_count?: string;
  // use_case?: string;
  // notes?: string;
};

export const validateRequired = (
  obj: BusinessProfile | BusinessProfileCompany | BusinessProfileContact,
  omit?: string[]
): ValidationErrors => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      const errors = validateRequired(value, omit);
      if (isEmpty(errors)) return acc;

      return { ...acc, [key]: errors };
    }

    const shouldIgnore = omit && omit.includes(key);

    if (!value && !shouldIgnore) {
      acc[key] = 'This field is required';
    }

    return acc;
  }, {} as ValidationErrors);
};

export const getCompanyStepValidationErrors = (p: BusinessProfile): ValidationErrors => {
  const errors = {} as ValidationErrors;

  const company = validateRequired(p.company, ['address2']) as CompanyValidationErrors;
  if (!isEmpty(company)) errors.company = company;

  return errors;
};

export const getContactStepValidationErrors = (p: BusinessProfile): ValidationErrors => {
  const errors = {} as ValidationErrors;

  const primaryContact = validateRequired(p.primaryContact) as ContactValidationErrors;
  if (!isEmpty(primaryContact)) errors.primaryContact = primaryContact;

  const secondaryContact = validateRequired(p.secondaryContact) as ContactValidationErrors;
  if (!isEmpty(secondaryContact)) errors.secondaryContact = secondaryContact;

  return errors;
};

export const isCompanyStepValid = (p?: BusinessProfile | null): boolean => {
  if (!p) return false;
  return isEmpty(getCompanyStepValidationErrors(p));
};

export const isContactStepValid = (p?: BusinessProfile | null): boolean => {
  if (!p) return false;
  return isEmpty(getContactStepValidationErrors(p));
};

const Onboarding = () => {
  const { step: stepParam } = useParams<{ step: string }>();
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();
  const [step, setStep] = useState<Step>(REGISTERED_STEPS[0].slug);
  const { push } = useHistory();
  const location = useLocation<{ redirect?: string }>();
  const [redirect] = useState<string | undefined>(location.state?.redirect);

  useEffect(() => {
    const isValidStep = Object.values(STEPS)
      .map(s => s.slug)
      .includes(stepParam as Step);
    if (!isValidStep) {
      push(`/onboarding/${REGISTERED_STEPS[0].slug}`);
      setStep(REGISTERED_STEPS[0].slug);
      return;
    }

    setStep(stepParam as Step);
  }, [push, stepParam]);

  const updateStep = useCallback(
    (step: Step) => {
      push(`/onboarding/${step}`);
      setStep(step);
    },
    [push]
  );

  if (profileLoading) return <Loading />;
  if (!profile?.user) return <Redirect to="/" />;
  if (profile?.activeAccount?.onboardingCompletedAt) return <Redirect to={location.state?.redirect || '/'} />;

  const bp = profile.activeAccount?.businessProfile || newBusinessProfile();

  return (
    <PageLayout>
      <LayoutHeader />
      <Main>
        <PageContainer>
          <Helmet>
            <title>Onboarding | datascore</title>
          </Helmet>

          <Container>
            <PageHeader>Onboarding</PageHeader>

            {REGISTERED_STEPS.map((s, i) => {
              if (s.slug !== step) {
                return <s.link key={s.slug} />;
              } else {
                const prevSlug = i > 0 ? REGISTERED_STEPS[i - 1].slug : undefined;
                const nextSlug = i < REGISTERED_STEPS.length - 1 ? REGISTERED_STEPS[i + 1].slug : undefined;

                return (
                  <s.component
                    key={s.slug}
                    businessProfile={bp}
                    onBack={() => prevSlug && updateStep(prevSlug)}
                    onContinue={() => nextSlug && updateStep(nextSlug)}
                    redirect={redirect}
                  />
                );
              }
            })}
          </Container>
        </PageContainer>
      </Main>
    </PageLayout>
  );
};

export default Onboarding;
