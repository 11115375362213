import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useDeleteUserInviteMutation } from 'src/api/admin/user-invites';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  id: string;
};

const DeleteUserInvite = ({ id }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useDeleteUserInviteMutation();

  const onClose = () => {
    setOpen(false);
    setApiMessage(undefined);
  };

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      const data = await mutateAsync(id);

      if (!data.success) {
        setApiMessage({ success: false, status: 400, message: data.message || 'Unknown Error' });
        return;
      }

      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [id, mutateAsync]);

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="red" icon title="Delete">
          <Icon name="trash" />
        </Button>
      }
    >
      <Modal.Header>Delete Invite</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button color="red" fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteUserInvite;
