import { MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { useGetUserProfileQuery, useLogoutMutation } from 'src/api/auth';
import { ProfileButton, ProfileMenuItem } from './styles';

const UserDropdown = () => {
  const { data } = useGetUserProfileQuery();
  const { mutateAsync: logout } = useLogoutMutation();

  const onClickLogout = useCallback(
    async (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      try {
        await logout();
      } catch (e: any) {}
    },
    [logout]
  );

  if (!data?.user) return null;

  return (
    <ProfileMenuItem>
      <Dropdown
        trigger={
          <ProfileButton>
            <span>{data.user.email[0]}</span>
          </ProfileButton>
        }
        icon={null}
        direction="left"
      >
        <Dropdown.Menu>
          <Dropdown.Item disabled>
            <span>
              Signed in as:{' '}
              <strong>
                {data.user.firstname} {data.user.lastname}
              </strong>
            </span>
          </Dropdown.Item>

          <Link to={`/user/settings`} className="item">
            Profile
          </Link>

          <Dropdown.Divider />

          <Link to="/accounts" className="item">
            Switch Account
          </Link>
          <Dropdown.Item onClick={onClickLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ProfileMenuItem>
  );
};

export default UserDropdown;
