import { format, formatDuration, intervalToDuration } from 'date-fns';
import { Header, Table } from 'semantic-ui-react';

import { Note } from 'src/styles';
import theme from 'src/styles/theme';
import { Certificate, GeoLocation } from 'src/types';
import { DataLabel } from './styles';

const formatGeoLocation = (loc: GeoLocation | null): React.ReactNode => {
  if (!loc) return <DataLabel>Unknown</DataLabel>;
  return [loc.city, loc.subdivisionName, loc.countryName].filter(Boolean).join(', ');
};

type Props = {
  cert: Certificate;
};

const TabPaneDetails = ({ cert }: Props) => {
  return (
    <>
      <Header>When did they visit?</Header>

      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <DataLabel>Visit date:</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">{format(new Date(cert.startTime), 'MMMM d, yyyy')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <DataLabel>Start time:</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <span title={String(cert.startTime)}>{format(new Date(cert.startTime), 'h:mm:ss a')}</span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <DataLabel>End time:</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <span title={String(cert.endTime)}>{format(new Date(cert.endTime), 'h:mm:ss a')}</span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <DataLabel>Time on page:</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <span title={String(cert.duration)}>
                {formatDuration(intervalToDuration({ start: 0, end: cert.duration }))}
              </span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header>Where did they visit?</Header>

      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <DataLabel>Page URL:</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <a href={cert.url} target="_blank" rel="noreferrer">
                {cert.url}
              </a>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header>Who visited?</Header>

      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <DataLabel>IP Address:</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">{cert.ipAddress || <DataLabel>Unknown</DataLabel>}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <DataLabel>Geo Location (Approximate):</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">{formatGeoLocation(cert.geoLocation)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <DataLabel>Browser:</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">
              {cert.userAgent ? `${cert.userAgent.Name} ${cert.userAgent.Version}` : <DataLabel>Unknown</DataLabel>}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <DataLabel>Operating System:</DataLabel>
            </Table.Cell>
            <Table.Cell textAlign="right">
              {cert.userAgent ? `${cert.userAgent.OS} ${cert.userAgent.OSVersion}` : <DataLabel>Unknown</DataLabel>}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header>Consent Language</Header>

      <div
        style={{
          marginBottom: '1rem',
          padding: '1rem',
          border: '1px solid #eee',
          borderRadius: 4,
          maxHeight: 120,
          overflowY: 'auto',
        }}
      >
        {cert.consentLanguage ? (
          <p style={{ margin: 0 }}>{cert.consentLanguage}</p>
        ) : (
          <p style={{ margin: 0, color: theme.gray }}>Failed to locate...</p>
        )}
      </div>

      <Note>
        Please{' '}
        <a href="mailto:support@datascore.ai" style={{ textDecoration: 'underline' }} target="_blank" rel="noreferrer">
          let us know
        </a>{' '}
        if this is not correct so we can improve our accuracy in locating this text.
      </Note>
    </>
  );
};

export default TabPaneDetails;
