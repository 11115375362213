import { StyledCircle } from './styles';

const Shield = () => {
  return (
    <StyledCircle width="218" height="236" viewBox="0 0 218 236" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1671 45.0264C30.4006 42.4727 37.9291 39.4754 47.0161 36.5383C67.3796 29.9565 91.4198 25 108.799 25C126.178 25 150.218 29.9565 170.582 36.5383C179.669 39.4754 187.197 42.4727 192.431 45.0264C195.708 135.77 150.029 187.196 108.799 208.062C67.5685 187.196 21.8895 135.77 25.1671 45.0264ZM0.798888 33C1.54124 23.0001 66.7988 1.73114e-05 108.799 0C150.799 1.73114e-05 216.057 23.0001 216.799 33C225.373 148.5 163.299 213.5 108.799 235.5C54.2988 213.5 -7.77538 148.5 0.798888 33Z"
        fill="#5A6CF1"
      />
    </StyledCircle>
  );
};

export default Shield;
