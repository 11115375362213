import { useMutation, useQueryClient } from 'react-query';

import { AccountResponse } from '../accounts';
import { http } from '../http-common';

export type AssignUserAccessInput = {
  accountId: string;
  userIds: string[];
  roles: string[];
};

export const useAssignUserAccessToAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, ...input }: AssignUserAccessInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${accountId}/user-access`, input);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
      },
    }
  );
};

export type AssignAccountUserRoleInput = {
  accountId: string;
  userId: string;
  roles: string[];
};

export const useAssignAccountUserRoleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, ...input }: AssignAccountUserRoleInput) => {
      const res = await http.put<AccountResponse>(`/api/admin/accounts/${accountId}/user-access`, input);
      return res.data.account;
    },
    {
      onSuccess: (_, { accountId }) => {
        queryClient.invalidateQueries(['admin/accounts', accountId]);
      },
    }
  );
};

export type RemoveUserAccessInput = {
  accountId: string;
  userId: string;
};

export const useRemoveUserAccessFromAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ accountId, ...params }: RemoveUserAccessInput) => {
      console.log(params);
      const res = await http.delete(`/api/admin/accounts/${accountId}/user-access`, { params });
      return res;
    },
    {
      onSuccess: (_, { accountId }) => {
        queryClient.invalidateQueries(['admin/accounts', accountId]);
      },
    }
  );
};
