import { Header, Label, Message } from 'semantic-ui-react';

import { Row } from 'src/styles';
import { Account } from 'src/types';
import AccountBillingSubscriptionPayment from './AccountBillingSubscriptionPayment';

type Props = {
  account: Account;
};

const MonthlySubscription = ({ account: a }: Props) => {
  return (
    <>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Header as="h4" style={{ marginBottom: 0 }}>
          Monthly Subscription
        </Header>
      </Row>

      <div style={{ marginBottom: '1rem' }}>
        <Label size="big" style={{ marginRight: '0.25em' }}>
          ${(a?.billing.rate || 0).toLocaleString()}
          <Label.Detail>Per Month</Label.Detail>
        </Label>
        <Label color={a.billing.subscription.isActive ? 'green' : 'red'} size="big" style={{ marginRight: '0.25em' }}>
          {a.billing.subscription.isActive ? 'Active' : 'Inactive'}
          {!a.billing.subscription.isActive && (
            <Label.Detail>{a.billing.subscription.cancelledAt ? 'Cancelled' : 'Past Due'}</Label.Detail>
          )}
        </Label>
      </div>

      {!a.billing.defaultPaymentMethod && (
        <Message error>
          <Message.Header>Payment Method Required</Message.Header>
          <Message.Content>
            Before you can enable your monthly subscription, you must add a payment method and make it the default.
          </Message.Content>
        </Message>
      )}

      {!a.billing.subscription.isActive && <AccountBillingSubscriptionPayment account={a} />}
      {/* {a.billing.subscription.isActive && !a.billing.subscription.cancelledAt && (
        <Button type="button" color="red" disabled>
          <Icon name="ban" /> Cancel Subscription
        </Button>
      )} */}
    </>
  );
};

export default MonthlySubscription;
