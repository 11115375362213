import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Header, Message, Segment } from 'semantic-ui-react';

import { useGetAccountQuery } from 'src/api/admin/accounts';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import PaginatedTable from 'src/components/PaginatedTable';
import NotFound from 'src/pages/not-found';
import { PageContainer, PageHeader } from 'src/styles';
import AdminAccountBilling from './AdminAccountBilling';
import AdminAccountBillingEnabled from './AdminAccountBillingEnabled';
import AdminAccountOnboarding from './AdminAccountOnboarding';
import AdminAccountSettings from './AdminAccountSettings';
import AdminAccountUsersListBody from './AdminAccountUsersListBody';
import AssignUsersToAccount from './AssignUsersToAccount';
import InviteUserToAccount from './InviteUserToAccount';

type AccountConfigurationErrors = {
  name: string;
  errors: string[];
}[];

const AdminAccountsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data: account, isLoading } = useGetAccountQuery(id);

  const accountConfigurationErrors = useMemo(() => {
    const errors: AccountConfigurationErrors = [];

    if (account?.billing.enabled) {
      const b = account.billing;

      const billingErrors: string[] = [];

      if (!b.rate) {
        billingErrors.push('Missing Rate.');
      }
      if (!b.subscription.day) {
        billingErrors.push('Missing Day of the Month.');
      }
      if (!b.stripeCustomerID) {
        billingErrors.push('Missing Stripe Customer ID.');
      }
      if (!b.subscription.isActive && !b.subscription.cancelledAt) {
        billingErrors.push('Subscription is not active. Please have customer make initial payment on billing page.');
      }

      if (billingErrors.length > 0) {
        errors.push({ name: 'Billing', errors: billingErrors });
      }
    }

    if (account?.userAccess?.length === 0) {
      errors.push({
        name: 'User Access',
        errors: ['No users have been assigned to this account.'],
      });
    }

    return errors;
  }, [account]);

  if (isLoading) return <Loading />;
  if (typeof account == 'undefined') return <NotFound />;

  return (
    <ConsoleLayout hideMenubar>
      <PageContainer>
        <Helmet>
          <title>Edit - Accounts - Admin | leadguard</title>
        </Helmet>

        <PageHeader>
          <Breadcrumbs crumbs={['Admin', 'Accounts', 'Edit']} />
        </PageHeader>

        {accountConfigurationErrors.length > 0 && (
          <Message error>
            <Message.Header>Account configuration error(s) detected.</Message.Header>
            <Message.Content>
              <p>Please see below for more information.</p>
              {accountConfigurationErrors.map(section => (
                <div key={section.name}>
                  <p>
                    <strong>{section.name}</strong>
                  </p>
                  <ul>
                    {section.errors.map(error => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </Message.Content>
          </Message>
        )}

        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header>General Settings</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <AdminAccountSettings account={account} />
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column width={4}>
                <Header>Billing</Header>
                <AdminAccountBillingEnabled account={account} />
              </Grid.Column>
              <Grid.Column width={12} style={account.billing.enabled ? undefined : { opacity: '0.5' }}>
                <AdminAccountBilling account={account} />
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column width={4}>
                <Header>Onboarding</Header>
              </Grid.Column>

              <Grid.Column width={12}>
                <AdminAccountOnboarding account={account} />
              </Grid.Column>
            </Grid.Row>
            <Divider />

            <Grid.Row>
              <Grid.Column width={4}>
                <Header>User Access</Header>
                <AssignUsersToAccount
                  accountId={account.id}
                  omitUserIds={(account.userAccess || []).map(u => u.userId)}
                />

                <InviteUserToAccount accountId={account.id} />
              </Grid.Column>
              <Grid.Column width={12}>
                <PaginatedTable
                  headers={['Email', 'Role(s)', '']}
                  renderBody={() => (
                    <AdminAccountUsersListBody
                      userAccess={account.userAccess || []}
                      userInvites={account.userInvites || []}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </PageContainer>
    </ConsoleLayout>
  );
};

export default AdminAccountsEdit;
