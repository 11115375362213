import { format, formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Label, Table } from 'semantic-ui-react';

import { useListBillingSubscriptionPaymentsQuery } from 'src/api/accounts';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
// import EditBalanceTransaction from './EditBalanceTransaction';

const AdminAccountBalanceTransactionsListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const params = useParams<{ id: string }>();
  const { data: payments } = useListBillingSubscriptionPaymentsQuery({
    accountId: params.id,
    limit,
    offset: limit * (currentPage - 1),
  });

  useEffect(() => {
    let count = 1;
    if (typeof payments !== 'undefined') {
      count = Math.ceil(payments.total / limit);
    }
    setPageCount(count);
  }, [payments, limit, setPageCount]);

  return (
    <>
      {payments?.data.map(p => {
        const monthDate = new Date().setMonth(p.payload.month - 1);
        const monthStr = format(monthDate, 'MMMM');

        return (
          <Table.Row key={p.id}>
            <Table.Cell>
              <Row style={{ flexDirection: 'column' }}>
                <strong>Payment for month: {monthStr}</strong>
                <span style={{ color: theme.gray }}>Charge ID: {p.payload['stripe.chargeID']}</span>
              </Row>
            </Table.Cell>
            <Table.Cell>
              <Label title={p.createdAt}>
                {formatDistanceToNow(new Date(p.createdAt), { addSuffix: true, includeSeconds: true })}
              </Label>
            </Table.Cell>
            <Table.Cell>
              <Label color={p.amount > 0 ? 'green' : 'red'}>{p.amount.toFixed(2)}</Label>
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              {/* <EditBalanceTransaction transaction={t} /> */}
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminAccountBalanceTransactionsListBody;
