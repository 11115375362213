import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, TabProps } from 'semantic-ui-react';

import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import PaginatedTable from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { PageContainer, PageHeader, Row } from 'src/styles';
import AdminUserInvitesListBody from './AdminUserInvitesListBody';
import AdminUsersListPane from './AdminUsersListPane';
import InviteUser from './InviteUser';

const AdminUsersList = () => {
  const query = useSearchQuery();
  const { push } = useHistory();
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    switch (query.get('tab')) {
      case 'invites':
        setActiveTab(1);
        break;

      default:
      case 'users':
        setActiveTab(0);
        break;
    }
  }, [query]);

  const onTabChange = useCallback(
    (_event: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps): void => {
      setActiveTab(Number(activeIndex));

      const search = new URLSearchParams();
      search.set('tab', activeIndex === 0 ? 'users' : 'invites');
      push({ search: search.toString() });
    },
    [push]
  );

  const panes = [
    {
      menuItem: 'Users',
      render: () => <AdminUsersListPane />,
    },
    {
      menuItem: 'Invites',
      render: () => (
        <PaginatedTable
          key="invites"
          headers={['Expires At', 'Email', 'User Role', 'Account', 'Account Access Role(s)', 'Invite URL', '']}
          renderBody={AdminUserInvitesListBody}
        />
      ),
    },
  ];

  return (
    <ConsoleLayout hideMenubar>
      <PageContainer>
        <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <PageHeader style={{ marginBottom: 0 }}>
            <Breadcrumbs crumbs={['Admin', 'Users']} />
          </PageHeader>

          <InviteUser />
        </Row>

        <Tab
          menu={{ secondary: true, pointing: true }}
          renderActiveOnly
          activeIndex={activeTab}
          panes={panes}
          onTabChange={onTabChange}
        />
      </PageContainer>
    </ConsoleLayout>
  );
};

export default AdminUsersList;
