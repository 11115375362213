import { useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useRemovePaymentMethodMutation } from 'src/api/billing';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { BillingPaymentMethod } from 'src/types';

type Props = {
  paymentMethod: BillingPaymentMethod;
};

const RemovePaymentMethod = ({ paymentMethod: { id } }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useRemovePaymentMethodMutation();

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    try {
      await mutateAsync({ id });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button type="button" size="tiny" basic color="red" floated="right">
          Remove
        </Button>
      }
    >
      <Modal.Header>Remove Payment Method</Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <ApiMessage data={apiMessage} />

          <Row>
            <Button type="button" fluid onClick={onClose}>
              Cancel
            </Button>
            <Button loading={isLoading} color="red" fluid>
              Remove
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default RemovePaymentMethod;
