/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { Button, Form, Header, Segment } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useGetUserProfileQuery } from 'src/api/auth';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import { Note, PageContainer, PageHeader, Row } from 'src/styles';

declare global {
  interface Window {
    leadguard: any;
  }
}

const DemoForm = () => {
  const { data: profile, isLoading: profileLoading } = useGetUserProfileQuery();
  const { data: apiTokens, isLoading: apiTokensLoading } = useListAccountApiTokensQuery({
    accountId: profile?.activeAccount?.id || '',
    limit: 1,
    offset: 0,
  });

  useEffect(() => {
    const token = apiTokens?.data[0].token || '';
    if (!token) return;

    (function (d, i) {
      let p, r;
      i.__SV ||
        ((window.leadguard = i),
        (i._i = []),
        (i.init = function (t: string, c: any) {
          ((p = d.createElement('script')).type = 'text/javascript'),
            (p.async = !0),
            (p.id = 'lg-recorder-js'),
            (p.src = c.api_host + '/dist/recorder.js'),
            (r = d.getElementsByTagName('script')[0]).parentNode?.insertBefore(p, r);
          i._i.push([t, c]);
        }),
        (i.__SV = 1));
    })(document, window.leadguard || []);
    window.leadguard.init(token, { api_host: process.env.REACT_APP_SERVER_URL, form_selector: '.lg-form' });

    window.leadguard.start && window.leadguard.start();

    return () => {
      window.leadguard.stop();
    };
  }, [apiTokens?.data]);

  if (profileLoading) return <Loading />;
  if (!profile?.user) return <Redirect to="/auth/login" />;
  if (!profile?.activeAccount) return <Redirect to="/accounts" />;
  if (apiTokensLoading) return <Loading />;

  return (
    <ConsoleLayout>
      <PageContainer>
        <Helmet>
          <title>Demo Form | leadguard</title>
        </Helmet>

        <PageHeader>Demo Form</PageHeader>

        <Segment>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Header style={{ textAlign: 'center' }}>Welcome</Header>
              <Note>Scroll down to enter your information</Note>
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <img
                alt="Unsplash Bayo Adegunloye"
                src="img/unsplash/bayo-adegunloye-Ck8CBZQbm9Q.jpg"
                style={{ display: 'block', width: 400 }}
              />
            </div>
          </Row>
        </Segment>

        <Segment>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <img
                alt="Unsplash Rocky Xiong"
                src="img/unsplash/rocky-xiong-cEoq58JkoDw.jpg"
                style={{ display: 'block', width: 400 }}
              />
            </div>

            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Header style={{ textAlign: 'center' }}>Keep Scrolling</Header>
              <Note>The form is just a little farther</Note>
            </div>
          </Row>
        </Segment>

        <Segment>
          <Form className="lg-form">
            <Form.Group widths="equal">
              <Form.Input name="first_name" label="First Name" />
              <Form.Input name="last name" label="Last Name" />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input name="email address" label="Email Address" placeholder="user@domain.com" />
              <Form.Input name="Primary Phone Number" label="Phone Number" placeholder="123-456-7890" />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input name="address1" label="Address 1" />
              <Form.Input name="city" label="City" />
              <Form.Input name="state" label="State" />
              <Form.Input name="Postal-Code" label="Zip" />
            </Form.Group>

            <Form.Checkbox
              name="terms"
              label="You have read, understood, and agree to our Terms of Service and Privacy Policy. You consent to the
            collection, use, and processing of your personal data as described in our policies. You understand that your
            data will be used to process your request, improve our services, and for other purposes outlined in our
            policies."
            />

            <Form.Field>
              <label style={{ display: 'flex', gap: '0.5rem' }}>
                <input type="checkbox" /> A normal styled checkbox
              </label>
            </Form.Field>

            <Button color="blue">Submit</Button>
          </Form>
        </Segment>

        <Segment>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Header style={{ textAlign: 'center' }}>Too Far</Header>
              <Note>You just passed the form</Note>
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <img
                alt="Unsplash Mathew Jungling"
                src="img/unsplash/matthew-jungling-rqBqeTgkcjw.jpg"
                style={{ display: 'block', width: 400 }}
              />
            </div>
          </Row>
        </Segment>

        <Segment>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <img
                alt="Unsplash Uran Wang"
                src="img/unsplash/uran-wang-gqkjgoI4S5c.jpg"
                style={{ display: 'block', width: 400 }}
              />
            </div>

            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Header style={{ textAlign: 'center' }}>This is the end</Header>
              <Note>Nothing more to see here</Note>
            </div>
          </Row>
        </Segment>
      </PageContainer>
    </ConsoleLayout>
  );
};

export default DemoForm;
