import { Link } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { Note, Row } from 'src/styles';
import { StepBox } from './styles';
import { STEPS } from './types';

const StepSubscriptionPaymentBox = () => {
  const { data: profile } = useGetUserProfileQuery();
  const isValid = !!profile?.activeAccount?.billing.defaultPaymentMethod;

  return (
    <Link to={`/onboarding/${STEPS.SUBSCRIPTION_PAYMENT.slug}`}>
      <StepBox>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Header>{STEPS.SUBSCRIPTION_PAYMENT.title}</Header>
            <Note>{STEPS.SUBSCRIPTION_PAYMENT.description}</Note>
          </div>

          <Icon
            title={isValid ? 'Completed' : 'Incomplete'}
            name={isValid ? 'check' : 'ellipsis horizontal'}
            size="big"
            color={isValid ? 'green' : 'grey'}
          />
        </Row>
      </StepBox>
    </Link>
  );
};

export default StepSubscriptionPaymentBox;
