import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { Form } from 'semantic-ui-react';

import ApiMessage from 'src/components/ApiMessage';

type Props = {
  redirect: string;
};

const AddPaymentMethodForm = ({ redirect }: Props) => {
  const [formLoading, setFormLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: `${process.env.REACT_APP_CLIENT_URL}/${redirect}` },
    });

    if (error) {
      setMessage(error.message);
    }

    setIsLoading(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <PaymentElement onReady={() => setFormLoading(false)} />
      <Form.Button
        color="blue"
        disabled={!stripe}
        fluid
        loading={isLoading || formLoading}
        style={{ marginTop: '1rem' }}
      >
        Add
      </Form.Button>
      <ApiMessage data={{ success: false, message }} />
    </Form>
  );
};

export default AddPaymentMethodForm;
