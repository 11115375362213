import { Label, Table } from 'semantic-ui-react';

import { AccountUserAccess, AdminUserInvite } from 'src/types';
import DeleteUserInvite from '../../users/list/DeleteUserInvite';
import AssignAccountUserAccessRole from './AssignAccountUserAccessRole';
import RemoveUserAccess from './RemoveUserAccess';

type Props = {
  userAccess: AccountUserAccess[];
  userInvites: AdminUserInvite[];
};

const AdminAccountUsersListBody = ({ userAccess, userInvites }: Props) => {
  return (
    <>
      {userInvites.map(invite => (
        <Table.Row key={invite.email}>
          <Table.Cell>
            {invite.email} <Label size="small">Invited</Label>
          </Table.Cell>
          <Table.Cell>
            {invite.accountAccessRoles?.map(role => (
              <Label>{role}</Label>
            ))}
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <DeleteUserInvite id={invite.id} />
          </Table.Cell>
        </Table.Row>
      ))}

      {userAccess.map(ua => (
        <Table.Row key={`${ua.accountId}-${ua.userId}`}>
          <Table.Cell>{ua.user?.email}</Table.Cell>
          <Table.Cell>
            <AssignAccountUserAccessRole accountId={ua.accountId} userId={ua.userId} roles={ua.roles} />
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <RemoveUserAccess accountId={ua.accountId} userId={ua.userId} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default AdminAccountUsersListBody;
