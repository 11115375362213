import { ReactNode, useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { SendSupportRequestInput, useSendSupportRequest } from 'src/api/slack';
import ApiMessage from 'src/components/ApiMessage';

type ValidationErrors = {
  message: string;
};

type Props = {
  trigger: ReactNode;
};

const SupportModal = ({ trigger }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync: sendSupportRequest, isLoading } = useSendSupportRequest();
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const [formdata, setFormdata] = useState<SendSupportRequestInput>({ message: '' });

  const onClose = useCallback(() => {
    setFormdata({ message: '' });
    setErrors({} as ValidationErrors);
    setApiMessage(undefined);
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!formdata.message) {
      setErrors(prev => ({ ...prev, message: 'Message is required' }));
      return;
    }

    setErrors({} as ValidationErrors);

    try {
      await sendSupportRequest(formdata);
      onClose();
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, onClose, sendSupportRequest]);

  return (
    <Modal size="tiny" open={open} onClose={onClose} onOpen={() => setOpen(true)} trigger={trigger}>
      <Modal.Header>Submit Support Request</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <p>
          Please provide a detailed description of the problem you are experiencing. Give as much detail about your
          situation as you can. Someone from our team will reach out to you via email as soon as we can
        </p>

        <Form onSubmit={handleSubmit}>
          <Form.TextArea
            label="Message"
            name="message"
            value={formdata.message}
            error={errors.message}
            onChange={(_, { value }) => setFormdata(prev => ({ ...prev, message: String(value) }))}
          />

          <Button content="Submit" color="blue" fluid loading={isLoading} />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default SupportModal;
