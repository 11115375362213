import capitalize from 'lodash/capitalize';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { useGetUserProfileQuery } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useCompleteOnboardingMutation } from 'src/api/onboarding';
import ApiMessage from 'src/components/ApiMessage';
import { Note, Row } from 'src/styles';
import { BusinessProfileCompany, BusinessProfileContact, newBusinessProfile } from 'src/types';
import { StepBox } from './styles';
import { StepProps, STEPS } from './types';

const ProfileLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
`;

const ProfileItem = styled.div`
  label {
    color: ${p => p.theme.gray};
    font-size: 0.8rem;
  }

  p {
    margin-bottom: 0 !important;
  }
`;

const Unknown = () => <span style={{ opacity: 0.15 }}>Unknown</span>;

const StepReview = ({ onBack, redirect }: StepProps) => {
  const { push } = useHistory();
  const { data: profile } = useGetUserProfileQuery();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync: completeOnboarding, isLoading } = useCompleteOnboardingMutation();

  const onSubmit = useCallback(async () => {
    try {
      await completeOnboarding(profile?.activeAccount?.id || '');
      push(redirect || '/');
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [completeOnboarding, profile?.activeAccount?.id, push, redirect]);

  const bp = profile?.activeAccount?.businessProfile || newBusinessProfile();

  return (
    <Form onSubmit={onSubmit}>
      <StepBox>
        <Header>{STEPS.REVIEW.title}</Header>
        <Note>{STEPS.REVIEW.description}</Note>

        <div style={{ marginBottom: '2rem' }}>
          <Header as="h4">Business Profile</Header>
          <ProfileLayout>
            {(Object.keys(bp.company) as (keyof BusinessProfileCompany)[]).map(key => {
              return (
                <ProfileItem key={key}>
                  <label>{capitalize(key.replace(/_/g, ' '))}</label>
                  <p>{bp.company[key] || <Unknown />}</p>
                </ProfileItem>
              );
            })}
          </ProfileLayout>

          <Header as="h4">Primary Contact</Header>
          <ProfileLayout>
            {(Object.keys(bp.primaryContact) as (keyof BusinessProfileContact)[]).map(key => {
              return (
                <ProfileItem key={key}>
                  <label>{capitalize(key.replace(/_/g, ' '))}</label>
                  <p>{bp.primaryContact[key] || <Unknown />}</p>
                </ProfileItem>
              );
            })}
          </ProfileLayout>

          <Header as="h4">Secondary Contact</Header>
          <ProfileLayout>
            {(Object.keys(bp.secondaryContact) as (keyof BusinessProfileContact)[]).map(key => {
              return (
                <ProfileItem key={key}>
                  <label>{capitalize(key.replace(/_/g, ' '))}</label>
                  <p>{bp.secondaryContact[key] || <Unknown />}</p>
                </ProfileItem>
              );
            })}
          </ProfileLayout>
        </div>

        <ApiMessage data={apiMessage} />

        <Row style={{ justifyContent: 'flex-end' }}>
          <Button type="button" basic onClick={onBack}>
            <Icon name="chevron left" />
            Back
          </Button>

          <Button color="blue" loading={isLoading}>
            Complete Onboarding
            <Icon name="chevron right" />
          </Button>
        </Row>
      </StepBox>
    </Form>
  );
};

export default StepReview;
