import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/accounts';
import { Account } from 'src/types';

type Props = {
  account: Account;
};

type Formdata = Pick<Account, 'name'>;

type ValidationErrors = {
  name?: string;
};

const getInitialFormdata = (account: Account): Formdata => {
  return {
    name: account.name || '',
  };
};

const AccountGeneralSettings = ({ account }: Props) => {
  const [formdata, setFormdata] = useState<Formdata>(() => getInitialFormdata(account));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountMutation();

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (typeof input.name === 'undefined') {
      validationErrors.name = 'name is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = useCallback(async () => {
    if (!isEmpty(validate(formdata))) {
      return;
    }

    await mutateAsync({ id: account.id, name: formdata.name });
  }, [account.id, mutateAsync, formdata, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />

      <Button color="blue" loading={mutationIsLoading}>
        Save
      </Button>
    </Form>
  );
};

export default AccountGeneralSettings;
