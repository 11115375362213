import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, CheckboxProps, DropdownProps, Form, Grid, InputOnChangeData } from 'semantic-ui-react';

import { useUpdateUserMutation } from 'src/api/admin/users';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { User, UserRoles } from 'src/types';

type ValidationErrors = {
  firstname?: string;
  lastname?: string;
  role?: string;
};

type Props = {
  user: User;
};

const AdminUserSettings = ({ user }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<User>(user);
  const { isLoading, mutateAsync } = useUpdateUserMutation();

  const onChange = useCallback((_, { checked, name, value }: CheckboxProps | DropdownProps | InputOnChangeData) => {
    const v = typeof checked !== 'undefined' ? checked : value;
    setFormdata(prev => ({ ...prev, [name]: v }));
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    const validationErrors = {} as ValidationErrors;

    if (formdata.firstname === '') {
      validationErrors.firstname = 'Firstname is required';
    }
    if (formdata.lastname === '') {
      validationErrors.lastname = 'Lastname is required';
    }
    if (formdata.role === '') {
      validationErrors.role = 'Role is required';
    }

    setErrors(validationErrors);

    if (!isEmpty(validationErrors)) {
      return;
    }

    try {
      await mutateAsync(formdata);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, mutateAsync]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Form.Checkbox label="Enabled?" name="enabled" checked={formdata.enabled} toggle onChange={onChange} />

            <Form.Group widths="equal">
              <Form.Input
                label="Firstname"
                name="firstname"
                value={formdata.firstname}
                onChange={onChange}
                error={errors.firstname}
              />
              <Form.Input
                label="Lastname"
                name="lastname"
                value={formdata.lastname}
                onChange={onChange}
                error={errors.lastname}
              />
            </Form.Group>

            <Form.Group>
              <Form.Select
                label="Role"
                name="role"
                value={formdata.role}
                error={errors.role}
                options={UserRoles.map(r => ({ ...r, key: r.value }))}
                onChange={onChange}
              />
            </Form.Group>

            <Button color="blue" loading={isLoading}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminUserSettings;
