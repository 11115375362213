import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export const GridCenter = styled.div`
  display: grid;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: #eee;
`;

const fadeInContainer = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 800px;
  min-height: 500px;
  background: white;
  box-shadow: 0 9px 46px 8px rgb(0 0 0 / 14%), 0 11px 15px -7px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 20%);
  border-radius: 4px;
  transform: translateY(0) scale(1);
  animation: 1200ms ${fadeInContainer} cubic-bezier(0.1, 0.8, 0.24, 1);
`;

export const ContainerLeft = styled.div`
  padding: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  form {
    width: 100%;
  }
`;

export const ContainerRight = styled.div`
  background: ${p => p.theme.brand};
  overflow: hidden;
  position: relative;
  padding: 3rem;
  text-align: right;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const Title = styled(Link)`
  color: white;
  font-size: 1.7rem;
  font-weight: normal;

  &:hover {
    color: white;
  }
`;

const fadeInCircle = keyframes`
  0% {
    opacity: 0;
    transform: translate(-10%, -10%) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: translate(-20%, -20%) scale(1);
  }
`;

export const StyledCircle = styled.svg`
  position: absolute;
  width: 450px;
  height: 450px;
  bottom: -225px;
  right: -225px;
  transform: translate(-20%, -20%) scale(1);
  animation: 1200ms ${fadeInCircle} cubic-bezier(0.1, 0.8, 0.24, 1);
`;
