import { useCallback, useState } from 'react';
import { Button, Form, Header, Icon, StepProps } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useGetAccountBillingDetailsQuery } from 'src/api/billing';
import { ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Note, Row } from 'src/styles';
import AccountBillingPaymentMethods from '../account/billing/AccountBillingPaymentMethods';
import MonthlySubscription from '../account/billing/MonthlySubscription';
import { StepBox } from './styles';
import { STEPS } from './types';

const StepSubscriptionPayment = ({ onBack, onContinue }: StepProps) => {
  const { data: profile } = useGetUserProfileQuery();
  const { data: paymentMethods } = useGetAccountBillingDetailsQuery();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();

  const a = profile?.activeAccount;
  const billing = a?.billing;
  const billingEnabled = billing?.enabled || false;
  const billingRate = billing?.rate || 0;
  const billingIsActive = billing?.subscription.isActive || false;

  const onSubmit = useCallback(() => {
    if (!paymentMethods || paymentMethods.length === 0) {
      setApiMessage({ success: false, message: 'Please add a payment method to continue.' });
      return;
    }

    if (billingEnabled && billingRate > 0 && !billingIsActive) {
      setApiMessage({
        success: false,
        message: 'Please activate the subscription by making your first payment to continue.',
      });
      return;
    }

    onContinue();
  }, [billingEnabled, billingIsActive, billingRate, onContinue, paymentMethods]);

  return (
    <StepBox>
      <Header>{STEPS.SUBSCRIPTION_PAYMENT.title}</Header>
      <Note>{STEPS.SUBSCRIPTION_PAYMENT.description}</Note>

      <div style={{ marginBottom: '1rem' }}>
        <AccountBillingPaymentMethods redirect="#/onboarding/subscription-payment" />

        {a && billingEnabled && billingRate > 0 && (
          <div style={{ marginTop: '1rem' }}>
            <MonthlySubscription account={a} />
          </div>
        )}
      </div>

      <Form onSubmit={onSubmit}>
        <ApiMessage data={apiMessage} />

        <Row style={{ justifyContent: 'flex-end' }}>
          <Button type="button" basic onClick={onBack}>
            <Icon name="chevron left" />
            Back
          </Button>

          {apiMessage && (
            <Button type="button" color="blue" basic onClick={onContinue}>
              Continue Anyways
              <Icon name="chevron right" />
            </Button>
          )}

          <Button color="blue">
            Continue
            <Icon name="chevron right" />
          </Button>
        </Row>
      </Form>
    </StepBox>
  );
};

export default StepSubscriptionPayment;
