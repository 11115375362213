import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Header } from 'semantic-ui-react';

import { AcceptInviteInput, useAcceptInviteMutation } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData, APIValidationError } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { UserInvite } from 'src/types';

type ValidationErrors = {
  firstname?: string;
  lastname?: string;
  email?: string;
  password?: string;
  confirm?: string;
  // tos?: string;
};

type Props = {
  invite: UserInvite;
};

const AcceptInviteForm = ({ invite }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<AcceptInviteInput>({
    firstname: '',
    lastname: '',
    email: invite.email,
    password: '',
    confirm: '',
    token: invite.token,
    tos: false,
  });
  const { mutateAsync, isLoading: mutationLoading } = useAcceptInviteMutation();
  const { push } = useHistory();

  const validate = useCallback((input: AcceptInviteInput): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (input.firstname === '') {
      validationErrors.firstname = 'First name is required';
    }
    if (input.lastname === '') {
      validationErrors.lastname = 'Last name is required';
    }
    if (input.password === '') {
      validationErrors.password = 'Password is required';
    }
    if (input.confirm === '') {
      validationErrors.confirm = 'Confirm Password is required';
    } else if (input.confirm !== input.password) {
      validationErrors.confirm = 'Confirm Password does not match password';
    }
    // if (input.tos === false) {
    //   validationErrors.tos = 'You must agree to the Terms of Service';
    // }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = async () => {
    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      const submitData = {
        ...formdata,
        email: formdata.email.toLowerCase().trim(),
      };

      setFormdata(submitData);

      await mutateAsync(submitData);
      push({ pathname: `/welcome` });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);

      if (e.response && e.response.data && e.response.data.errors) {
        const { errors } = e.response.data;

        const validationErrors: ValidationErrors = {};

        errors.forEach(({ field, error }: APIValidationError) => {
          switch (field.toLowerCase()) {
            case 'firstname':
              validationErrors.firstname = error;
              break;

            case 'lastname':
              validationErrors.lastname = error;
              break;

            case 'email':
              validationErrors.email = error;
              break;

            case 'password':
              validationErrors.password = error;
              break;
          }
        });

        setErrors(validationErrors);
      }
    }
  };

  const onChange = useCallback(
    (_, { name, value, checked }) => setFormdata(prev => ({ ...prev, [name]: checked ?? value })),
    []
  );

  return (
    <Form onSubmit={onSubmit}>
      <Header>👋 Welcome to Leadguard !</Header>

      <ApiMessage data={apiMessage} />

      <Form.Group widths="equal">
        <Form.Input
          label="First name"
          name="firstname"
          value={formdata.firstname}
          onChange={onChange}
          error={errors.firstname}
        />

        <Form.Input
          label="Last name"
          name="lastname"
          value={formdata.lastname}
          onChange={onChange}
          error={errors.lastname}
        />
      </Form.Group>

      <Form.Input label="Email" readOnly value={formdata.email} error={errors.email} />

      <Form.Input
        label="Password"
        type="password"
        name="password"
        value={formdata.password}
        onChange={onChange}
        error={errors.password}
      />

      <Form.Input
        label="Confirm Password"
        type="password"
        name="confirm"
        value={formdata.confirm}
        onChange={onChange}
        error={errors.confirm}
      />

      {/* <Form.Checkbox
        checked={formdata.tos}
        onChange={onChange}
        error={errors.tos}
        name="tos"
        label={
          <label>
            I have read and agree to the{' '}
            <strong>
              <Link to="/tos">Terms of Service</Link>
            </strong>
          </label>
        }
      /> */}

      <Button fluid color="blue" loading={mutationLoading} style={{ marginBottom: '1rem' }}>
        Create Account
      </Button>

      <p style={{ textAlign: 'center' }}>
        Already have an account?{' '}
        <strong>
          <Link to="/">Log in</Link>
        </strong>
      </p>
    </Form>
  );
};

export default AcceptInviteForm;
